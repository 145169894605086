import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authenticateUser } from '../API/authentication'
import Splash from '../modules/Authentication/Splash'
import API from '../API'

export const BootstrapApp = ({ children }) => {
  const [isAppReady, setAppReady] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    const init = async () => {
      try {
        const isAuthenticated = await authenticateUser()
        setAppReady(true)
        const rememberMe = localStorage.getItem('rememberMe') || false

        if (/^\/fit\/[^/]+$/.test(location.pathname)) {
          return
        }

        if (isAuthenticated && rememberMe) {
          navigate(
            location.pathname === '/signin'
              ? '/'
              : location.pathname
              ? location.pathname
              : '/'
          )
        } else if (isAuthenticated && !rememberMe) {
          await API.auth.signOut()
          navigate('/signin')
        } else {
          navigate('/signin')
        }
      } catch (e) {
        setAppReady(true)
        navigate('/signin')
      }
    }

    init()
  }, [])

  return isAppReady ? <>{children}</> : <Splash />
}
