import React, { useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useRecoilState } from 'recoil'
import { progressAtom } from '../recoil/loader'

export const LinearProgressWithLabel = () => {
  const [value, setValue] = useRecoilState(progressAtom)
  useEffect(() => {
    if (value === 100) {
      setValue(0)
    }
  }, [value])
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value || 0} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="inherit">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
