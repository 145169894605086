import { atom, selector } from 'recoil'
import { User } from '../types/user'

const localStorageEffect =
  (
    //@ts-ignore TODO: fix this
    key
  ) =>
  //@ts-ignore TODO: fix this
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }
    //@ts-ignore TODO: fix this
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const signInAtom = atom({
  key: 'signInAtom',
  default: { username: '', password: '' }
})

export const awsUserAtom = atom({
  key: 'awsUserAtom',
  default: {}
})

export const userAtom = atom({
  key: 'userAtom',
  default: {} as User | null
})

export const userImageAtom = atom({
  key: 'userImageAtom',
  default: null
})

export const userOnboardingAtom = atom({
  key: 'userOnboardingAtom',
  default: false
})

export const userLocationAtom = atom({
  key: 'userLocationAtom',
  default: {}
})

export const devicesAtom = atom({
  key: 'devicesAtom',
  default: []
})

export const activeDevicesState = selector({
  key: 'activeDevices',
  get: ({ get }) => {
    const devices = get(devicesAtom)
    //@ts-ignore TODO: fix this
    const activeDevices = devices?.filter(device => device?.deleted_at === null)
    return activeDevices || []
  }
})

export const authorizedUsersAtom = atom({
  key: 'authorizedUsersAtom',
  default: []
})

export const spoofedIdAtom = atom({
  key: 'spoofedIdAtom',
  default: null as string | null,
  effects: [localStorageEffect('spoofedId')]
})

export const workoutHistoryAtom = atom({
  key: 'workoutHistoryAtom',
  default: []
})

export const userEmailAtom = atom({
  key: 'userEmailAtom',
  default: ''
})
