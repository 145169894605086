import { Page, LinkButton, Title, Text } from '../../components'
import styled from '@emotion/styled'
import dots from '../../assets/images/moxy-dots.png'
import phones from '../../assets/Phone_Scene_01.png'
import close from '../../assets/images/close.png'
import MySVG from '../../components/Icons'
import { useNavigate } from 'react-router-dom'

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 45px;
`

const AppWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: row;
`

const TextWrapper = styled.div``

const MoxyTitle = styled(Title)`
  color: ${p => p.theme.colors.black};
  text-transform: uppercase;
  font-family: 'Oswald';
  font-size: 36px;
`

const Subheading = styled(Text)`
  font-size: 26px;
  width: 475px;
  line-height: 31px;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.white};
`

const StyledPage = styled(Page)`
  background-image: url(${dots});
  background-position: 5% 15%;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  width: 1024px;
  height: 640px;
  background: white;
  padding: 20px;
  margin-top: 5%;

  .Wrapper__closeLink {
    display: flex;
  }

  .LearnMore__text {
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    width: 50%;
    h1 {
      font-size: 24px;
      font-family: 'SFPro-Semibold';
      text-transform: uppercase;
    }
    p {
      font-size: 18px;
      padding-right: 20%;
      line-height: 30px;
    }
  }
  .LearnMore__text__icons {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
`

const PhonesImage = styled.div`
  background-image: url(${phones});
  background-size: contain;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
`

const CloseImage = styled.div`
  background-image: url(${close});
  background-size: cover;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
`

const LearnMore = () => {
  const navigate = useNavigate()

  return (
    <StyledPage>
      <Wrapper>
        <LinkButton
          className="Wrapper__closeLink"
          onClick={() => navigate('/signin')}>
          <CloseImage />
        </LinkButton>
        <PhonesImage />
        <ColumnWrapper>
          <TextWrapper>
            <MoxyTitle>Download the Moxy mobile app</MoxyTitle>
            <Subheading>
              Create an account and pair your sensors on mobile, and be able to
              track workout data on mobile or desktop.
            </Subheading>
            <AppWrapper>
              <img src={MySVG.AppStore} alt="App Store Logo" />
              <img src={MySVG.GooglePlay} alt="Google Play Logo" />
            </AppWrapper>
          </TextWrapper>
        </ColumnWrapper>
      </Wrapper>
    </StyledPage>
  )
}
export default LearnMore
