import styled from '@emotion/styled'

const ImgContainer = styled.div`
  position: relative;
  flex-basis: 100%;
  flex-basis: calc(33.333% - 20px);
  cursor: pointer;
  transition: 0.5s all ease-in;
`

const Img = styled.img`
  cursor: pointer;
  width: 100%;
`

export const Image = ({ source, ...props }) => {
  return (
    <ImgContainer {...props}>
      <Img src={source} />
    </ImgContainer>
  )
}
