export const capitalize = (string = '') =>
  string && `${string[0].toUpperCase()}${string.slice(1)}`

export const toTimeString = date => {
  let dateObj = new Date(date * 1000)
  let hours = dateObj.getUTCHours().toString().padStart(2, '0')
  let minutes = dateObj.getUTCMinutes().toString().padStart(2, '0')
  let seconds = dateObj.getSeconds().toString().padStart(2, '0')
  if (hours === '00') {
    return `${minutes}:${seconds}`
  } else {
    return `${hours}:${minutes}:${seconds}`
  }
}
