import { ButtonHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import { BaseView, BaseViewProps } from './Base'
interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    BaseViewProps {
  isAction?: boolean
  small?: boolean
  color?: 'primary' | 'secondary'
}

const BaseButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
  color: ${p => p.theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  font-family: 'Oswald';
  cursor: pointer;
  height: 48px;
  ${p =>
    p.small &&
    css`
      padding: 10px;
    `}
  ${props =>
    props.disabled
      ? `
          opacity: 0.3;
          &:hover {
            opacity: 0.2;
          }
        `
      : `
          opacity: 1;
          &:hover {
            opacity: 0.9;
          }
        `};
  ${BaseView}
`

const PrimaryButton = styled(BaseButton)`
  background: linear-gradient(135.73deg, #3e9cdc 16.27%, #2363b0 87.61%);
  border: none;
  transition: opacity 0.2s ease-in-out;
`

const Cancel = styled(BaseButton)`
  background: ${p => p.theme.colors.errorRed};
  border: none;
  transition: opacity 0.2s ease-in-out;
`

const StyledLinkButton = styled.button`
  display: flex;
  background: transparent;
  border: none;
  color: white;
  font-weight: 600;
  font-family: 'SFProText-Heavy';
  cursor: pointer;
  ${BaseView}
`

const StyledSimpleButton = styled.button`
  background: transparent;
  display: flex;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  ${BaseView}
`

const StyledOutlineButton = styled(BaseButton)`
  border: 2px solid white;
  background: transparent;
`

export const SimpleButton = (
  props: ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return <StyledSimpleButton {...props} />
}

export const LinkButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <StyledLinkButton {...props} />
}

export const Button = ({ children, isAction, ...props }: ButtonProps) => {
  return (
    <PrimaryButton {...props}>
      {isAction ? (
        <CircularProgress color="inherit" size={props.small ? 20 : 30} />
      ) : (
        children
      )}
    </PrimaryButton>
  )
}

export const CancelButton = ({ children, isAction, ...props }: ButtonProps) => {
  return (
    <Cancel {...props}>
      {isAction ? (
        <CircularProgress color="inherit" size={props.small ? 20 : 30} />
      ) : (
        children
      )}
    </Cancel>
  )
}

export const OutlineButton = (props: ButtonProps) => {
  return <StyledOutlineButton {...props} />
}
