import React from 'react'
import { Text } from '../../../../components'

import styled from '@emotion/styled'
import EnduranceIcon from './../../../../assets/images/endurance.png'
import FreeWorkoutIcon from './../../../../assets/images/free-workout.png'
import IntervalIcon from './../../../../assets/images/interval.png'
import StrengthIcon from './../../../../assets/images/strength.png'
import theme from '../../../../theme'
import { capitalize, toTimeString } from '../../../../utils/text-utils'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

const GraphItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #44444f;

  max-height: 400px;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-corner {
    background-color: black;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #307ec5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
  }

  overflow: scroll;
  flex: 1;

  .GraphItemWrapper__loader {
    display: flex;

    justify-content: center;
    align-items: center;
    flex: 1;
  }
`

const GraphItem = styled.article`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .GraphItem__wrapper {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .GraphItem__text {
    padding-left: 12px;
    align-items: center;
  }

  .GraphItem__link {
    display: flex;
    flex: 1;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    &:hover {
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.4);
    }
  }
`

const TimeContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding: 15px;
  min-width: 195px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  background: ${theme.colors.darkGrey};
  font-family: 'Oswald';

  .totalTime {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 0.9px;
  }

  .time {
    font-family: 'Oswald';
    margin-left: 5px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2.125px;
  }
`

const GraphArea = ({ highlights = [], loading }) => {
  const getWorkoutColor = type => {
    switch (type) {
      case 'strength':
        return theme.colors.blue
      case 'interval':
        return theme.colors.red
      case 'steadyState':
      case 'endurance':
        return theme.colors.orange
      case 'freeWorkout':
      case 'free':
        return theme.colors.green
      default:
        return theme.colors.green
    }
  }

  const getWorkoutIcon = type => {
    switch (type) {
      case 'strength':
        return `${StrengthIcon}`
      case 'interval':
        return `${IntervalIcon}`
      case 'steadyState':
      case 'endurance':
        return `${EnduranceIcon}`
      case 'freeWorkout':
      case 'free':
        return `${FreeWorkoutIcon}`
      default:
        return `${FreeWorkoutIcon}`
    }
  }
  console.log(highlights)
  return (
    <GraphItemWrapper>
      {!loading ? (
        highlights.length ? (
          highlights.map((highlight, key) => (
            <GraphItem key={key}>
              <Link
                className="GraphItem__link"
                to={`/data-detail/${highlight.workout_id}`}>
                <div className="GraphItem__wrapper">
                  <img
                    style={{ width: '55px' }}
                    alt="workout icon"
                    src={getWorkoutIcon(highlight?.type)}
                  />
                  <div className="GraphItem__text">
                    <Text color={getWorkoutColor(highlight?.type)}>
                      {capitalize(highlight?.type)}
                    </Text>

                    <Text>{capitalize(highlight?.name)}</Text>
                    <Text fontSize="14px" mt={1}>
                      See Full Workout
                    </Text>
                  </div>
                </div>

                {isNaN(highlight?.totalTime) ? (
                  highlight?.ended_at ? (
                    ''
                  ) : (
                    <TimeContainer>
                      <p className="totalTime">Live Workout</p>
                    </TimeContainer>
                  )
                ) : (
                  <TimeContainer>
                    <p className="totalTime">Total Time:</p>
                    <p className="time">{toTimeString(highlight.totalTime)}</p>
                  </TimeContainer>
                )}
              </Link>
            </GraphItem>
          ))
        ) : (
          <div className="GraphItemWrapper__loader">
            No highlights for this date
          </div>
        )
      ) : (
        <div className="GraphItemWrapper__loader">
          <CircularProgress />
        </div>
      )}
    </GraphItemWrapper>
  )
}

export default GraphArea
