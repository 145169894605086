import { css } from '@emotion/react'

import Oswald from './Oswald.woff'
import Oswald2 from './Oswald.woff2'
import SFPro from './SFPro-Regular.woff'
import SFPro2 from './SFPro-Regular.woff2'
import SFProTextRegular from './SFProText-Regular.ttf'
import SFProTextHeavy from './SFProText-Heavy.ttf'

export default css`
  @font-face {
    font-family: 'Oswald';
    src: local('Oswald'), local('Oswald'), url(${Oswald}) format('woff'),
      url(${Oswald2}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SFProText-Regular';
    src: local('SFProText-Regular'), local('SFProText-Regular'),
      url(${SFProTextRegular}) format('ttf');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SFPro-Regular';
    src: local('SFPro-Regular'), local('SFPro-Regular'),
      url(${SFPro}) format('woff'), url(${SFPro2}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SFProText-Heavy';
    src: local('SFProText-Heavy'), local('SFProText-Heavy'),
      url(${SFProTextHeavy}) format('ttf');
    font-style: normal;
  }
`
