import {
  opacity,
  layout,
  color,
  space,
  typography,
  flexbox,
  shadow,
  position,
  border,
  SpaceProps,
  LayoutProps,
  ColorProps,
  PositionProps,
  FlexboxProps,
  TypographyProps,
  BorderProps,
  ShadowProps,
  OpacityProps
} from 'styled-system'

export interface BaseViewProps
  extends SpaceProps,
    LayoutProps,
    ColorProps,
    PositionProps,
    FlexboxProps,
    TypographyProps,
    BorderProps,
    ShadowProps {}

export interface BaseTextProps
  extends SpaceProps,
    PositionProps,
    OpacityProps,
    LayoutProps,
    TypographyProps,
    BorderProps,
    ColorProps {}

export const BaseView = [
  space,
  layout,
  color,
  position,
  flexbox,
  position,
  typography,
  border,
  shadow
]

export const BaseText = [
  space,
  position,
  opacity,
  layout,
  typography,
  border,
  color
]
