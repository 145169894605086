import React, { useEffect, useMemo, useState } from 'react'
import HistoricalWorkoutData from './HistoricalWorkoutData/HistoricalWorkoutData'
import LiveWorkoutData from './LiveWorkoutData/LiveWorkoutData'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { spoofedIdAtom, workoutHistoryAtom } from '../../../recoil/user'
import API from '../../../API'
import {
  differenceInHours,
  parseISO,
  addSeconds,
  isAfter,
  isBefore
} from 'date-fns'
import ContinueWorkoutModal from './ContinueWorkoutModal'
import { toast } from 'react-toastify'
import { ToastContainer } from '../../../components'
import { progressAtom } from '../../../recoil/loader'
import { useSubscription } from '../../../hooks/useSubscription'

let intervalTimer, isWorkoutEndedTimer

const Data = () => {
  const [isActive, setIsActive] = useState(false)
  const { isPremium } = useSubscription()
  const spoofedId = useRecoilValue(spoofedIdAtom)
  const [workoutHistory, setWorkoutHistory] = useRecoilState(workoutHistoryAtom)
  const [devices, setDevices] = React.useState([])
  const [workout, setWorkout] = React.useState(null)
  const [hasHr, setHasHr] = React.useState(false)
  const [showContinue, setShowContinue] = React.useState(false)
  const [selectedSensor, setSelectedSensor] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [fetchingWorkoutHistory, setFetchingWorkoutHistory] =
    React.useState(true)
  const [workoutDevices, setWorkoutDevices] = React.useState([])
  const controller = useMemo(() => new AbortController())
  const setProgress = useSetRecoilState(progressAtom)
  const setWorkoutSensors = (workout_metrics, devices) =>
    new Promise((res, rej) => {
      const workoutDevices = workout_metrics
        ?.filter(m => m.type !== 'heartrate')
        ?.map(m =>
          devices
            .filter(d => d.user_sensor_id === m.user_sensor_id)
            .reduce((_, device) => device, false)
        )
        ?.filter(d => d)

      if (workoutDevices?.length) {
        const uniqueSetOfDevices = [...new Set(workoutDevices)]
        setWorkoutDevices(uniqueSetOfDevices)
        const sensor = uniqueSetOfDevices?.[0]

        if (sensor) {
          setSelectedSensor([sensor])
          return res(sensor)
        }
      }
      rej('no_workout_devices')
    })

  useEffect(() => {
    const getWorkout = async () => {
      try {
        const workout = await API.workout.getWorkout(
          workoutHistory[workoutHistory.length - 1].workout_id
        )
        let workout_metrics = await API.workout.getWorkoutMetricsBatch(
          workoutHistory[workoutHistory.length - 1].workout_id,
          workout?.metricsCount,
          controller
        )
        const hasHR = workout_metrics?.find(m => m.type === 'heartrate')
        setHasHr(hasHR)
        await setWorkoutSensors(workout_metrics, devices)
        setWorkout({ ...workout, workout_metrics })

        if (!workout?.ended_at && isPremium) {
          startInterval(workout_metrics.length)
          if (
            differenceInHours(new Date(), parseISO(workout?.started_at)) > 1
          ) {
            //seems workout has been going on for a while continue?
            setShowContinue(true)
          }
        }
      } catch (e) {
        console.log(e)
        if (e !== 'no_workout_devices' && e?.message !== 'canceled') {
          toast.error('Something went wrong loading the workout...', {
            position: toast.POSITION.TOP_CENTER
          })
        }
      }
      setLoading(false)
    }

    if (workoutHistory?.length && devices?.length) {
      getWorkout()
    }

    return () => {
      controller.abort()
      setProgress(0)
    }
  }, [workoutHistory, devices])

  const getWorkouts = async spoofedId => {
    setLoading(true)
    setFetchingWorkoutHistory(true)
    if (spoofedId) {
      let user = await API.user.getUserById(spoofedId)
      if (user) {
        setDevices(user?.user_sensors)
      }
    } else {
      let user = await API.user.getUser()
      if (user) {
        setDevices(user?.user_sensors)
      }
    }
    const workouts = await API.workout.getWorkouts(spoofedId)
    setWorkoutHistory(workouts)
    setFetchingWorkoutHistory(false)
  }

  React.useEffect(() => {
    setIsActive(true)
    return () => {
      setIsActive(false)
      clearInterval(intervalTimer)
      clearInterval(isWorkoutEndedTimer)
    }
  }, [setIsActive])

  React.useEffect(() => {
    refresh()
  }, [spoofedId])

  const refresh = () => {
    controller.abort()
    clearInterval(intervalTimer)
    clearInterval(isWorkoutEndedTimer)
    setWorkoutHistory([])
    setWorkout(null)
    if (spoofedId) {
      getWorkouts(spoofedId)
    } else {
      getWorkouts()
    }
  }

  const startInterval = async workoutMetricsLength => {
    let lastFetchedIndex = workoutMetricsLength

    intervalTimer = setInterval(async () => {
      if (isActive) {
        let workout_metrics = await API.workout.getWorkoutMetrics(
          workoutHistory[workoutHistory.length - 1].workout_id,
          {
            skip: lastFetchedIndex,
            take: 100
          }
        )

        if (workout_metrics?.length) {
          lastFetchedIndex += workout_metrics.length
          if (!hasHr) {
            let hasHR = workout_metrics?.find(m => m.type === 'heartrate')
            setHasHr(hasHR)
          }
          setWorkout(prev => ({
            ...prev,
            workout_metrics: [...prev.workout_metrics, ...workout_metrics]
          }))
        }
      }
    }, 7500)

    isWorkoutEndedTimer = setInterval(async () => {
      if (isActive) {
        let workout = await API.workout.getWorkout(
          workoutHistory[workoutHistory.length - 1].workout_id
        )

        if (workout?.ended_at) {
          lastFetchedIndex = 0
          clearInterval(intervalTimer)
          clearInterval(isWorkoutEndedTimer)
          const workouts = await API.workout.getWorkouts(spoofedId)
          setWorkoutHistory(workouts)
        }
      }
    }, 15000)
  }

  const getCurrentIndex = () => {
    return workoutDevices
      .map((s, index) => {
        if (s?.user_sensor_id === selectedSensor?.[0]?.user_sensor_id) {
          return index
        }
        return false
      })
      .filter(s => s !== false)
      .reduce((memo, index) => index, null)
  }

  const setNext = () => {
    const currentIndex = getCurrentIndex()

    if (currentIndex !== null) {
      const nextIndex =
        currentIndex + 1 >= workoutDevices.length ? 0 : currentIndex + 1

      setSelectedSensor([workoutDevices[nextIndex]])
    }
  }

  const setPrev = () => {
    const currentIndex = getCurrentIndex()

    if (currentIndex !== null) {
      const prevIndex =
        currentIndex - 1 < 0 ? workoutDevices.length - 1 : currentIndex - 1

      setSelectedSensor([workoutDevices[prevIndex]])
    }
  }

  const handleCloseContinue = () => {
    setShowContinue(false)
  }

  const handleConfirmContinue = async () => {
    setShowContinue(false)

    let workout = await API.workout.getWorkout(
      workoutHistory[workoutHistory.length - 1].workout_id
    )

    let workout_metrics = await API.workout.getWorkoutMetrics(
      workoutHistory[workoutHistory.length - 1].workout_id
    )

    const sortedMetrics = workout_metrics?.sort((a, b) =>
      isAfter(parseISO(a.recorded_at), parseISO(b.recorded_at))
        ? 1
        : isBefore(parseISO(a.recorded_at), parseISO(b.recorded_at))
        ? -1
        : 0
    )

    const patch = {
      ended_at: addSeconds(
        parseISO(sortedMetrics[sortedMetrics.length - 1]?.recorded_at),
        1
      )
    }

    await API.workout.finishWorkout(workout.workout_id, patch)
    const finishedWorkout = await API.workout.getWorkout(workout.workout_id)
    const finishedWorkoutMetrics = await API.workout.getWorkoutMetrics(
      workout.workout_id
    )
    setWorkout({ ...finishedWorkout, workout_metrics: finishedWorkoutMetrics })
    setLoading(false)
  }
  return (
    <>
      <LiveWorkoutData
        selectedSensor={selectedSensor}
        setNext={setNext}
        setPrev={setPrev}
        workout={workout}
        hasHr={hasHr}
        loading={loading}
        workoutDevices={workoutDevices}
        refresh={refresh}
      />
      <HistoricalWorkoutData
        workout={workoutHistory}
        loading={fetchingWorkoutHistory}
      />
      <ContinueWorkoutModal
        open={showContinue}
        handleClose={handleCloseContinue}
        handleConfirm={handleConfirmContinue}
      />
      <ToastContainer />
    </>
  )
}

export default Data
