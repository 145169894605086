import React from 'react'
import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import MySVG from './../../../../components/Icons'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 30px;
`

const SensorCarousel = ({ selectedSensor, next, prev, sensors }) => {
  return (
    <Wrapper>
      {sensors.length > 1 && (
        <IconButton onClick={prev}>
          <img src={MySVG.LeftArrow} alt="Left arrow" />
        </IconButton>
      )}

      <h3>{selectedSensor?.[0]?.name}</h3>

      {sensors.length > 1 && (
        <IconButton onClick={next}>
          <img src={MySVG.RightArrow} alt="Right arrow" />
        </IconButton>
      )}
    </Wrapper>
  )
}

export default SensorCarousel
