import React from 'react'
import { Text } from './Text'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import theme from './../theme'

const BreadcrumbsText = styled(Text)`
  color: ${p => p.theme.colors.blue};
  text-transform: uppercase;
`

const DateText = styled(Text)`
  color: ${p => p.theme.colors.white};
  text-transform: uppercase;
`

export const BreadcrumbsNav = ({ date }) => {
  return (
    <Breadcrumbs
      style={{ marginLeft: 5 }}
      separator={
        <NavigateNextIcon
          style={{ color: `${theme.colors.white}` }}
          fontSize="small"
        />
      }
      aria-label="breadcrumb">
      <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
        <BreadcrumbsText>Data</BreadcrumbsText>
      </Link>
      <DateText>{date}</DateText>
    </Breadcrumbs>
  )
}
