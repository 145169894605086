import styled from '@emotion/styled'
import { useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { NavLink as Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../../../../API'
import MoxyLogo from '../../../../assets/Moxy-tagline-logo-white.png'
import {
  Button,
  Image,
  TextFieldInput,
  TextFieldPasswordForm,
  Title,
  ToastContainer
} from '../../../../components'
import { Form } from '../../../../components'
import MySVG from '../../../../components/Icons'
import { CreateAccountSuccess } from './CreateAccountSuccess'

const LinkWrapper = styled.div`
  margin: 35px auto 0;
  width: 75%;
`

const SideWrapper = styled(Grid)`
  background-color: ${p => p.theme.colors.black};
  @media (max-width: 599px) {
    display: none;
  }
`

const Wrapper = styled(Grid)`
  min-height: 100vh;
  position: relative;
  background-color: ${p => p.theme.colors.darkGrey};
  margin-top: 50px;
  padding-left: 25px !important;
  @media (max-width: 599px) {
    padding-left: 35px !important;
    padding-right: 20px !important;
  }
`

const SaveButton = styled(Button)`
  width: 360px;
  margin-top: 35px;

  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const CreateAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  height: 55px;
  border-radius: 12px;
  padding: 12px;
  font-family: 'SFProText-Regular';
  font-size: 14px;
  background-color: #2e2f30;
  color: #307ec5;

  img {
    margin-right: 10px;
    filter: invert(48%) sepia(37%) saturate(961%) hue-rotate(167deg)
      brightness(86%) contrast(90%);
  }

  @media (max-width: 699px) {
    margin: 10px;
    padding: 0;
    img {
      margin-right: 0;
    }
    div {
      display: none;
    }
  }
`

const InnerFormWrapper = styled.div`
  margin-left: 15px;
  width: 75%;
  @media (max-width: 699px) {
    width: 100%;
    margin-left: 0;
  }
`

const CreateAccount = () => {
  const [isLoading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isAction, setAction] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    formState: { errors }
  } = useForm()

  const SITEKEY = '6LdrhrgoAAAAAMNMXNMoOZznTsljEN4e598d4lHX'
  const firstName = register('first_name', { required: true })
  const lastName = register('last_name', { required: true })
  const email = register('email', { required: true })
  const password = register('password', { required: true })
  const matches = useMediaQuery('(max-width:599px)')

  function onChange(value: any) {
    console.log('Captcha value:', value)
  }

  const saveChanges = async () => {
    setLoading(true)
    setAction(true)
    const values = getValues()
    try {
      await API.auth.signUp({
        given_name: values.first_name,
        family_name: values.last_name,
        email: values.email,
        password: values.password
      })

      setShowSuccess(true)
      setAction(false)
      setLoading(false)
      reset()
    } catch (error) {
      setAction(false)
      setLoading(false)
      console.log(error)
      //@ts-ignore
      const message = error?.message || 'Error signing up user'
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  return (
    <Grid container spacing={2}>
      <SideWrapper item xs={0} md={3} lg={2}>
        <LinkWrapper>
          <Link to="/signin">
            <Image source={MoxyLogo} />
          </Link>
        </LinkWrapper>

        <CreateAccountWrapper>
          <img
            className="button__icon--blue"
            src={MySVG.Account}
            alt="Bluetooth icon"
          />

          <div>Create Account</div>
        </CreateAccountWrapper>
      </SideWrapper>

      {showSuccess ? (
        <CreateAccountSuccess />
      ) : (
        <Wrapper item xs={12} md={9} lg={10}>
          <Title>Create Account</Title>

          <Form onSubmit={handleSubmit(saveChanges)}>
            <InnerFormWrapper>
              <TextFieldInput
                label="First Name *"
                type="text"
                error={Boolean(errors.first_name)}
                name={firstName.name}
                onChange={firstName.onChange}
                inputRef={firstName.ref}
                helperText={errors.first_name && 'First name is required'}
              />
              <TextFieldInput
                label="Last Name *"
                name={lastName.name}
                error={Boolean(errors.last_name)}
                onChange={lastName.onChange}
                inputRef={lastName.ref}
                helperText={errors.last_name && 'Last name is required'}
              />
              <TextFieldInput
                label="Email *"
                name={email.name}
                error={Boolean(errors.email)}
                onChange={email.onChange}
                inputRef={email.ref}
                helperText={errors.email && 'Email is required'}
              />
              <TextFieldPasswordForm
                control={control}
                label="Password *"
                name={password.name}
                error={Boolean(errors.password)}
                inputRef={password.ref}
                helperText={errors.password && 'Password is required'}
              />
              <ButtonWrapper>
                {/* @ts-ignore FIXME */}
                <ReCAPTCHA
                  sitekey={SITEKEY}
                  onChange={onChange}
                  size={matches ? 'compact' : 'normal'}
                />

                <SaveButton disabled={isAction} type="submit">
                  {isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    'Create Account'
                  )}
                </SaveButton>
              </ButtonWrapper>
            </InnerFormWrapper>
          </Form>

          <ToastContainer />
        </Wrapper>
      )}
    </Grid>
  )
}
export default CreateAccount
