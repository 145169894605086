import * as auth from './authentication'
import * as user from './user'
import * as workout from './workout'
import * as sensor from './sensor'
import * as subscription from './subscription'

const controller = new AbortController()

const API = { auth, user, workout, sensor, controller, subscription }

export default API
