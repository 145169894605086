import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import MySVG from '../../../../components/Icons'

const Wrapper = styled(Grid)`
  ${p => p.theme.colors.white};
  height: 100vh;
  flex: 1;
  position: relative;
  background-color: ${p => p.theme.colors.darkGrey};
  padding: 52px 25px;
  color: #fff;
  @media (max-width: 599px) {
    padding: 52px 5px;
  }
`

const InnerWrapper = styled.div`
  max-width: 475px;
  text-align: center;
  margin: 90px 80px;

  @media (max-width: 699px) {
    margin: 90px 10px;
  }
`

const HeaderText = styled.h2`
  font-family: SF Pro Text;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  @media (max-width: 699px) {
    font-size: 26px;
  }
`
const SubheaderText = styled.h3`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
`
export const CreateAccountSuccess = () => {
  return (
    <Wrapper item xs={12} md={9} lg={10}>
      <InnerWrapper>
        <img src={MySVG.CheckmarkCircle} alt="checkmark circle" />
        <HeaderText>Your account has been created</HeaderText>
        <SubheaderText>
          You can close out of this window and sign in on your mobile app to
          start connecting sensors!
        </SubheaderText>
      </InnerWrapper>
    </Wrapper>
  )
}
