import APIKit from './apiFactory'
import { setRecoil } from 'recoil-nexus'
import { actionAtom, progressAtom } from '../recoil/loader'
import queryString from 'query-string'

export const getWorkouts = async spoofedId => {
  try {
    if (spoofedId) {
      let { data: workouts } = await APIKit.get(
        `/workout/authed_user/${spoofedId}`
      )

      return workouts.sort((a, b) =>
        a.workout_id > b.workout_id ? 1 : a.workout_id < b.workout_id ? -1 : 0
      )
    } else {
      let { data: workouts } = await APIKit.get('/workout')
      return workouts.sort((a, b) =>
        a.workout_id > b.workout_id ? 1 : a.workout_id < b.workout_id ? -1 : 0
      )
    }
  } catch (e) {
    console.log('Error loading the workouts', e)
    throw e
  }
}

export const getWorkout = async id => {
  try {
    const { data: workout } = await APIKit.get(`/workout/${id}?noMetrics=true`)
    return workout
  } catch (e) {
    console.log('Error loading the workout', e)
    throw e
  }
}

export const getWorkoutMetricsBatch = async (id, count, controller) => {
  let skip = 0
  let take = 500
  let metrics = []
  setRecoil(progressAtom, (skip / count) * 100)
  try {
    if (count > 500) {
      while (skip < count) {
        setRecoil(progressAtom, (skip / count) * 100)
        const query = queryString.stringify({ skip, take })
        const { data: workout } = await APIKit.get(
          `/workout/workout_metrics/${id}?${query}`,
          { signal: controller.signal }
        )
        metrics = metrics.concat(workout)
        skip += 500
      }
      setRecoil(progressAtom, 100)
      return metrics
    } else {
      const { data: metrics } = await APIKit.get(
        `/workout/workout_metrics/${id}`,
        { signal: controller.signal }
      )
      setRecoil(progressAtom, 100)
      return metrics
    }
  } catch (e) {
    console.log('Error getWorkoutMetrics', e)
    throw e
  }
}

export const getWorkoutMetrics = async (id, params = {}) => {
  try {
    const { skip, take } = params
    const query = queryString.stringify({ skip, take })
    const { data: workout } = await APIKit.get(
      `/workout/workout_metrics/${id}?${query}`
    )
    return workout
  } catch (e) {
    console.log('Error getWorkoutMetrics', e)
    throw e
  }
}

export const finishWorkout = async (id, patch) => {
  try {
    const res = await APIKit.patch(`/workout/${id}`, patch)
    return res
  } catch (e) {
    console.log(e)
    return {}
  }
}

export const createAnnotation = async (annotation, workout_id) => {
  try {
    const { data: workoutAnnotation } = await APIKit.post(
      `/workout/${workout_id}/annotation`,
      { annotation }
    )
    return workoutAnnotation
  } catch (e) {
    console.log(e)
    return e
  }
}

export const updateAnnotation = async (annotation, workout_id) => {
  try {
    await APIKit.patch(`/workout/${workout_id}/annotation`, { annotation })
    // return workoutAnnotation
  } catch (e) {
    console.log(e)
    return e
  }
}

export const patchWorkout = async (patch, workout_id) => {
  try {
    await APIKit.patch(`/workout/${workout_id}`, patch)
  } catch (e) {
    console.log(e)
    return e
  }
}

export const createSnapshot = async (snapshot, workout_id) => {
  try {
    await APIKit.post(`/workout/${workout_id}/snapshot`, { snapshot })
  } catch (e) {
    setRecoil(actionAtom, '')
    console.log(e)
    return e
  }
}

export const deleteSnapshot = async snapshotId => {
  try {
    setRecoil(actionAtom, `deletingSnapshot-${snapshotId}`)
    await APIKit.delete(`/workout/snapshot/${snapshotId}`)
  } catch (e) {
    console.log(e)
    setRecoil(actionAtom, '')
    return false
  }
}
export const getCSVFile = async id => {
  try {
    const res = await APIKit.post(`/workout/csv/${id}`, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    return res.data.url
  } catch (e) {
    console.log(e)
    return e
  }
}

export const getFitFile = async id => {
  try {
    const res = await APIKit.post(`/workout/fit/${id}`, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    return res.data.url
  } catch (e) {
    console.log(e)
    return e
  }
}
