const theme = {
  colors: {
    black: '#000000',
    grey: '#2E2F30',
    grey50: '#E6E5EB80',
    moxyGrey: '#939394',
    darkGrey: '#1C1C1C',
    white: '#FFFFFF',
    blue: '#307EC5',
    orange: '#FE7F1D',
    green: '#00B780',
    red: '#DF301A',
    errorRed: '#BB271B',
    moxy: ['#00B780', '#307EC5', '#7EC530', '#C5C530', '#E8E100']
  }
}

export default theme
