import Grid from '@mui/material/Grid'
import React from 'react'
import {
  Button,
  Form,
  SectionLoader,
  TextFieldInput,
  Title,
  ToastContainer
} from '../../../components'
import AccountSidebar from './AccountSidebar'

import API from '../../../API'

import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { actionAtom } from '../../../recoil/loader'
import { ButtonWrapper, InnerFormWrapper, Wrapper } from './styles'

const ChangePassword = () => {
  const [isLoading, setLoading] = React.useState(false)
  const [oldPassword, setCurrentPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirm, setConfirm] = React.useState('')
  const [isAction, setAction] = useRecoilState(actionAtom)
  const { handleSubmit } = useForm()

  const save = async () => {
    if (confirm === newPassword) {
      setLoading(true)
      setAction('changingPassword')
      const res = await API.auth.ChangePassword(oldPassword, newPassword)
      setAction('')
      if (res.code === 'SUCCESS') {
        toast.success('Password Updated!', {
          position: toast.POSITION.TOP_CENTER
        })
      } else if (res.code === 'FAILED') {
        toast.error(res.message, {
          position: toast.POSITION.TOP_CENTER
        })
      }
      setCurrentPassword('')
      setNewPassword('')
      setConfirm('')
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Title>Change Password</Title>

      <Form onSubmit={handleSubmit(save)}>
        <InnerFormWrapper>
          <TextFieldInput
            label="Current Password"
            required
            type="text"
            onChange={v => setCurrentPassword(v.target.value)}
          />
          <TextFieldInput
            label="New Password"
            required
            type="text"
            onChange={v => setNewPassword(v.target.value)}
          />
          <TextFieldInput
            label="Confirm New Password"
            required
            type="text"
            onChange={v => setConfirm(v.target.value)}
          />

          {confirm !== newPassword && <div>Passwords Must match</div>}

          {isLoading ? (
            <ButtonWrapper>
              <Button
                isAction={isAction === 'changingPassword'}
                disabled={Boolean(isAction)}
                type="submit">
                <SectionLoader />
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper mt="35px">
              <Button
                isAction={isAction === 'changingPassword'}
                disabled={Boolean(isAction)}
                type="submit">
                Update Password
              </Button>
            </ButtonWrapper>
          )}
        </InnerFormWrapper>
      </Form>
      <ToastContainer />
    </Wrapper>
  )
}

function ChangePasswordPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} md={4} lg={3}>
        <AccountSidebar />
      </Grid>
      <Grid item xs={10} md={8} lg={9}>
        <ChangePassword />
      </Grid>
    </Grid>
  )
}

export default ChangePasswordPage
