import Routes from './Routes'
import { Amplify } from 'aws-amplify'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const COGNITO_CLIENT_ID = '7ecn1qk3ptdrmrc3l7ih06ri4n'
const COGNITO_USER_POOL_ID = 'us-east-2_a2HAyR1Nc'

Amplify.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: 'us-east-2',
  userPoolId: COGNITO_USER_POOL_ID,
  userPoolWebClientId: COGNITO_CLIENT_ID
})

const stripePromise = loadStripe(
  'pk_live_51O5vI3LQBU7aXCHb3q8kZLlMpyAA7BR5QLLSr9pecMGPTrSEXUbDJRhWZ5hjr0dMArwedcW02SRFQl99hsiaMJmj00kchpyCfE'
)

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Routes />
    </Elements>
  )
}

export default App
