import { SectionLoader, LinkButton } from '../../../components'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { userAtom } from '../../../recoil/user'
import { useRecoilValue } from 'recoil'
import accountIcon from '../../../assets/Account.svg'
import subIcon from '../../../assets/subscription.svg'
import lockIcon from '../../../assets/Lock.svg'
import passwordIcon from '../../../assets/moxy-password.svg'
import exitIcon from '../../../assets/exit-log-out-arrow.svg'
import { NavLink as Link } from 'react-router-dom'
import placeholder from '../../../assets/profile-placeholder.png'
import API from '../../../API'
import { useNavigate } from 'react-router-dom'
import { actionAtom } from '../../../recoil/loader'
import { useMediaQuery } from '@mui/material'

const UserPhotoContainer = styled.div`
  display: block;
  justify-content: center;
  margin-top: 85px;
  margin-bottom: 30px;
  max-width: 250px;
`

const UserPhotoInnerContainer = styled.div<{ hasPhoto: boolean }>`
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 115px;
  height: 115px;
  align-items: center;
  display: flex;
  ${p =>
    p.hasPhoto &&
    css`
      border-radius: 60px;
    `}

  @media only screen and (max-width: 900px) {
    width: 65px;
    height: 65px;
  }
`

const UserPhoto = styled.img`
  height: 100%;
`

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 10px;

  .button__icon--blue {
    filter: invert(48%) sepia(37%) saturate(961%) hue-rotate(167deg)
      brightness(86%) contrast(90%);
  }

  .active {
    border: ${p => p.theme.colors.blue} solid 1px !important;
  }

  a:focus {
    border: ${p => p.theme.colors.blue} solid 1px !important;
  }

  @media only screen and (max-width: 700px) {
    /* display: none; */
  }
`

const LogoutButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  margin-top: 30px;
  flex: 1;
  max-width: 250px;
  p {
    color: ${p => p.theme.colors.blue};
    margin-left: 5px;
  }

  @media only screen and (max-width: 900px) {
    background: ${p => p.theme.colors.black};
    padding: 30px 20px;
    border-radius: 12px;
    margin-top: 0px;
    p {
      display: none;
    }
  }
`

const NavItem = styled(Link)`
  text-decoration: none;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 250px;
  margin-bottom: 15px;
  padding: 30px 20px;
  font-size: 16px;
  cursor: pointer;
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.black};
  border: ${p => p.theme.colors.black} solid 1px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
    justify-content: center;
  }
`

const LinkTo = styled.a`
  text-decoration: none;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-bottom: 15px;
  background: ${p => p.theme.colors.black};
  padding: 30px 20px;
  color: ${p => p.theme.colors.white};
  font-size: 16px;
  cursor: pointer;
  border: ${p => p.theme.colors.black} solid 1px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
    justify-content: center;
  }
`

const NavItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 35px;
  height: 35px;
`

const NavItemText = styled.span`
  padding-left: 10px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const UserName = styled.p`
  margin-top: 12px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
`

function AccountSidebar() {
  const user = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const isAction = useRecoilValue(actionAtom)
  const matches = useMediaQuery('(max-width:900px)')

  const logout = async () => {
    await API.auth.signOut()
    navigate('/signin')
  }

  return (
    <Wrapper>
      <UserPhotoContainer>
        <UserPhotoInnerContainer hasPhoto={Boolean(user?.photo)}>
          {isAction === 'savingImage' && <SectionLoader position="absolute" />}
          <UserPhoto
            src={user?.photo ? user?.photo : placeholder}
            alt="profile"
          />
        </UserPhotoInnerContainer>
        {matches ? null : (
          <UserName>
            {user?.first_name} {user?.last_name}
          </UserName>
        )}
      </UserPhotoContainer>
      <NavItem to="/account/" title="Profile">
        <NavItemContainer>
          <img
            className="button__icon--blue"
            src={accountIcon}
            alt="Account icon"
          />
        </NavItemContainer>
        <NavItemText>Profile Information</NavItemText>
      </NavItem>
      <NavItem to="/account/subscription" title="Subscription">
        <NavItemContainer>
          <img
            className="button__icon--blue"
            src={subIcon}
            alt="Subscription icon"
          />
        </NavItemContainer>
        <NavItemText>Subscription</NavItemText>
      </NavItem>
      <NavItem to="/account/change-password" title="Change Password">
        <NavItemContainer>
          <img
            className="button__icon--blue"
            src={passwordIcon}
            alt="Password icon"
          />
        </NavItemContainer>
        <NavItemText>Change Password</NavItemText>
      </NavItem>
      <LinkTo
        title="Terms & Privacy Policy"
        target="_blank"
        rel="noreferrer"
        href="https://www.moxymonitor.com/terms-conditions/">
        <NavItemContainer>
          <img className="button__icon--blue" src={lockIcon} alt="Lock icon" />
        </NavItemContainer>
        <NavItemText>Terms & Privacy Policy</NavItemText>
      </LinkTo>

      <LogoutButton onClick={logout} title="Log out">
        <img src={exitIcon} alt="exit icon" />
        <p>Log out</p>
      </LogoutButton>
    </Wrapper>
  )
}

export default AccountSidebar
