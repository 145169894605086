import { isAfter, differenceInSeconds, addSeconds } from 'date-fns'

export const INTERVAL_TIME_SECONDS = 5

export const getTimeSpread = (sm02Data = [], startTime, workoutState) => {
  const lastValue = sm02Data.length
    ? sm02Data[sm02Data.length - 1].x
    : Date.now()

  const spread = differenceInSeconds(lastValue, startTime)

  if (workoutState === 'stop') {
    if (startTime === lastValue) {
      return [startTime, lastValue + 1]
    }
    return [startTime, lastValue]
  }

  if (spread > 300) {
    const difference = spread - 300
    return [addSeconds(startTime, difference), lastValue]
  }
  if (startTime === lastValue) {
    return [startTime, lastValue + 1]
  }

  return [startTime, lastValue]
}

export const getDataSpread = (sm02Data = [], startTime, workoutState) => {
  const lastValue = sm02Data.length
    ? sm02Data[sm02Data.length - 1].x
    : Date.now()

  const spread = differenceInSeconds(lastValue, startTime)

  if (workoutState === 'stop') {
    return sm02Data
  }

  if (spread > 300) {
    const difference = spread - 300
    const newStart = addSeconds(startTime, difference)
    return sm02Data.filter(d => isAfter(d.x, newStart))
  }

  return sm02Data
}

export const thbDataEffect = (
  thbData = [],
  setThbMax = () => {},
  setThbMin = () => {}
) => {
  let minValues = thbData?.filter(v => !v.isSeed)
  const max = Math.max(...thbData?.map(v => v.y))
  let min = max - 0.5

  if (minValues.length) {
    min = Math.min(...minValues?.filter(v => v.y)?.map(v => v.y)) - 0.5
  }
  const values = { max: max + 0.25, min }
  setThbMax(max + 0.5)
  setThbMin(min)

  //FIXME: Commented out code since we handle null values now.
  //FIXME: prob need to delete this... wip
  // if (max < 10 && min < 10) {
  //   setThbMax(12)
  //   setThbMin(11.5)
  //   values.max = 12
  //   values.min = 11.5
  // } else {
  //   setThbMax(max + 0.25)
  //   values.max = max + 0.25

  //   if (max === min) {
  //     setThbMin(max - 0.5)
  //     values.min = max - 0.5
  //   } else if (min < 5) {
  //     setThbMin(11.5)
  //     values.min = 11.5
  //   } else {
  //     setThbMin(min - 0.25)
  //     values.min = min - 0.25
  //   }
  // }
  return values
}
