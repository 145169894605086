import React from 'react'

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar as RNMCalendar } from '@hassanmojab/react-modern-calendar-datepicker'

const Calendar = props => {
  return <RNMCalendar {...props} colorPrimary="#FE7F1D" />
}

export default Calendar
