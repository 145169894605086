export const lapColumns = [
  {
    field: 'lap',
    headerName: 'LAP',
    cellClassName: 'lap-cell',
    flex: 1
  },
  {
    field: 'duration',
    headerName: 'DURATION',
    cellClassName: 'lap-cell--low-opacity',
    flex: 1
  },
  {
    field: 'avgHr',
    headerName: 'AVG HR',
    cellClassName: 'lap-cell--low-opacity',
    flex: 1
  },
  {
    field: 'startTime',
    headerName: 'START TIME',
    sortable: false,
    cellClassName: 'lap-cell--low-opacity',
    flex: 1
  },
  {
    field: 'endTime',
    headerName: 'END TIME',
    sortable: false,
    cellClassName: 'lap-cell--low-opacity',
    flex: 1
  }
]

export const sensorPlacementOptions = [
  {
    id: 0,
    label: 'Left leg',
    value: { body_part_side: 'left', body_part: 'leg' }
  },
  {
    id: 1,
    label: 'Left calf',
    value: { body_part_side: 'left', body_part: 'calf' }
  },
  {
    id: 2,
    label: 'Left shin',
    value: { body_part_side: 'left', body_part: 'shin' }
  },
  {
    id: 3,
    label: 'Left hamstring',
    value: { body_part_side: 'left', body_part: 'hamstring' }
  },
  {
    id: 4,
    label: 'Left quad',
    value: { body_part_side: 'left', body_part: 'quad' }
  },
  {
    id: 5,
    label: 'Left glute',
    value: { body_part_side: 'left', body_part: 'glute' }
  },
  {
    id: 6,
    label: 'Right leg',
    value: { body_part_side: 'right', body_part: 'leg' }
  },
  {
    id: 7,
    label: 'Right calf',
    value: { body_part_side: 'right', body_part: 'calf' }
  },
  {
    id: 8,
    label: 'Right shin',
    value: { body_part_side: 'right', body_part: 'shin' }
  },
  {
    id: 9,
    label: 'Right hamstring',
    value: { body_part_side: 'right', body_part: 'hamstring' }
  },
  {
    id: 10,
    label: 'Right quad',
    value: { body_part_side: 'right', body_part: 'quad' }
  },
  {
    id: 11,
    label: 'Right glute',
    value: { body_part_side: 'right', body_part: 'glute' }
  },
  {
    id: 12,
    label: 'Torso back',
    value: { body_part_side: 'back', body_part: 'torso' }
  },
  {
    id: 13,
    label: 'Left lower back',
    value: { body_part_side: 'left', body_part: 'lowerback' }
  },
  {
    id: 14,
    label: 'Left upper back',
    value: { body_part_side: 'left', body_part: 'upperback' }
  },
  {
    id: 15,
    label: 'Right lower back',
    value: { body_part_side: 'right', body_part: 'lowerback' }
  },
  {
    id: 16,
    label: 'Right upper back',
    value: { body_part_side: 'right', body_part: 'upperback' }
  },
  {
    id: 17,
    label: 'Torso front',
    value: { body_part_side: 'front', body_part: 'torso' }
  },
  {
    id: 18,
    label: 'Left abdomen',
    value: { body_part_side: 'left', body_part: 'abdomen' }
  },
  {
    id: 19,
    label: 'Left chest',
    value: { body_part_side: 'left', body_part: 'chest' }
  },
  {
    id: 20,
    label: 'Right abdomen',
    value: { body_part_side: 'right', body_part: 'abdomen' }
  },
  {
    id: 21,
    label: 'Right chest',
    value: { body_part_side: 'right', body_part: 'chest' }
  },
  {
    id: 22,
    label: 'Left arm',
    value: { body_part_side: 'left', body_part: 'arm' }
  },
  {
    id: 23,
    label: 'Left shoulder',
    value: { body_part_side: 'left', body_part: 'shoulder' }
  },
  {
    id: 24,
    label: 'Left bicep',
    value: { body_part_side: 'left', body_part: 'bicep' }
  },
  {
    id: 25,
    label: 'Left tricep',
    value: { body_part_side: 'left', body_part: 'tricep' }
  },
  {
    id: 26,
    label: 'Left brachioradialis',
    value: { body_part_side: 'left', body_part: 'brachioradialis' }
  },
  {
    id: 27,
    label: 'Left forearm extensors',
    value: { body_part_side: 'left', body_part: 'forearm_extensors' }
  },
  {
    id: 28,
    label: 'Right arm',
    value: { body_part_side: 'right', body_part: 'arm' }
  },
  {
    id: 29,
    label: 'Right shoulder',
    value: { body_part_side: 'right', body_part: 'shoulder' }
  },
  {
    id: 30,
    label: 'Right bicep',
    value: { body_part_side: 'right', body_part: 'bicep' }
  },
  {
    id: 31,
    label: 'Right tricep',
    value: { body_part_side: 'right', body_part: 'tricep' }
  },
  {
    id: 32,
    label: 'Right brachioradialis',
    value: { body_part_side: 'right', body_part: 'brachioradialis' }
  },
  {
    id: 33,
    label: 'Right forearm extensors',
    value: { body_part_side: 'right', body_part: 'forearm_extensors' }
  },
  {
    id: 34,
    label: 'Neck',
    value: { body_part_side: null, body_part: 'neck' }
  },
  {
    id: 35,
    label: 'Throat',
    value: { body_part_side: null, body_part: 'throat' }
  },
  {
    id: 36,
    label: 'Waist mid back',
    value: { body_part_side: 'mid_back', body_part: 'waist' }
  },
  {
    id: 37,
    label: 'Waist front',
    value: { body_part_side: 'front', body_part: 'waist' }
  },
  {
    id: 38,
    label: 'Waist left',
    value: { body_part_side: 'left', body_part: 'waist' }
  },
  {
    id: 39,
    label: 'Waist right',
    value: { body_part_side: 'right', body_part: 'waist' }
  }
]

export const permissionOptions = [
  {
    id: 1,
    label: 'View',
    value: 'View'
  },
  {
    id: 2,
    label: 'Edit',
    value: 'Edit'
  }
]
