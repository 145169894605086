import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import MUISelect from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import { useId } from 'react'
import styled from '@emotion/styled'

const Select = styled(MUISelect)`
  background: ${p => p.theme.colors.darkGrey};
  border: none;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  margin-bottom: 20px;
  color: white;
`

const useStyles = makeStyles({
  root: {
    maxWidth: '95%;',
    marginTop: '5px',
    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: '5px',
      marginBottom: '15px'
    },
    '& .MuiInputLabel-root': {
      color: 'white',
      opacity: '0.5',
      zIndex: 1,
      marginBottom: '-15px'
    },
    '& .MuiInputBase-root': {
      paddingLeft: 0,
      marginBottom: 0,
      height: 'auto'
    },
    '& .MuiInputBase-input': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomStyle: 'dashed',
      borderBottom: '1px solid #979797'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #307EC5'
    },
    '& .MuiSelect-nativeInput': {
      lineHeight: '0'
    },
    '& .MuiSelect-icon': {
      color: 'white'
    }
  }
})
//@ts-ignore FIXME

type DropdownProps = {
  label: string
  children: React.ReactNode
  helperText?: string
  error?: boolean
  [x: string]: any
}

export const Dropdown = ({
  label,
  children,
  helperText,
  error,
  ...rest
}: DropdownProps) => {
  const classes = useStyles()
  const labelId = useId()
  return (
    <div className={classes.root}>
      <FormControl error={error}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select {...rest} labelId={labelId}>
          {children}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}
