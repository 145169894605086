import { userAtom } from '../recoil/user'
import {
  addMonths,
  differenceInDays,
  parseISO,
  endOfMonth,
  format
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

export function useSubscription() {
  const [isPremium, setIsPremium] = useState<boolean | undefined>(undefined)
  const user = useRecoilValue(userAtom)

  function getDaysLeftInThreeMonthSubscription(createdAt?: string): number {
    if (!createdAt) {
      return 0
    }
    const endDate = addMonths(parseISO(createdAt), 3)
    return differenceInDays(endDate, new Date())
  }

  function getEndOfSubscriptionDate(createdAt?: string): string | undefined {
    if (!createdAt) {
      return undefined
    }
    let endDate = new Date()
    if (user?.subscription?.type === 'trial') {
      endDate = addMonths(parseISO(createdAt), 3)
    }
    if (user?.subscription?.type === 'monthly') {
      endDate = endOfMonth(new Date())
    }

    return format(endDate, 'LLLL do, yyyy')
  }

  useEffect(() => {
    if (
      user?.subscription?.type === 'trial' ||
      user?.subscription?.type === 'monthly' ||
      user?.subscription?.type === 'lifetime'
    ) {
      setIsPremium(true)
    } else {
      setIsPremium(false)
    }
  }, [user])

  return {
    isPremium,
    type: user?.subscription?.type,
    trialLength: getDaysLeftInThreeMonthSubscription(
      user?.subscription?.created_at
    ),
    endDate: getEndOfSubscriptionDate(user?.subscription?.created_at)
  }
}
