import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, CancelButton } from './Button'
import styled from '@emotion/styled'
import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const ConfirmButton = styled(Button)`
  max-width: 335px;
`

const CancellationButton = styled(CancelButton)`
  max-width: 335px;
`

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    padding: 30px 40px;
  }
`

export function ConfirmSuccessDialog({
  open,
  handleClose,
  handleConfirm,
  isAction,
  titleText,
  text,
  confirmText,
  cancelText
}: any) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: 10, left: 10 }}>
        <IconButton onClick={handleClose}>
          <Close sx={{ width: 30, height: 30, color: 'black' }} />
        </IconButton>
      </Box>
      <DialogTitle sx={{ textAlign: 'center' }}>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <ConfirmButton
          autoFocus
          onClick={handleConfirm}
          isAction={isAction}
          small>
          {confirmText}
        </ConfirmButton>
        <CancellationButton autoFocus onClick={handleClose} small>
          {cancelText || 'Cancel'}
        </CancellationButton>
      </DialogActions>
    </Dialog>
  )
}
