import { Auth } from 'aws-amplify'
import { getRecoil, setRecoil } from 'recoil-nexus'

import { signInAtom, userAtom } from '../recoil/user'
import { authAtom } from '../recoil/auth'
import { loaderAtom } from '../recoil/loader'
import APIKit from './apiFactory'
import { getUser } from './user'
import { User } from '../types/user'

type SignUp = {
  given_name: string
  family_name: string
  password: string
  email: string
}

export async function signUp({
  given_name,
  family_name,
  password,
  email
}: SignUp) {
  try {
    setRecoil(signInAtom, { username: email, password })

    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name,
        family_name
      }
    })
  } catch (error) {
    throw error
  }
}

export async function signIn(
  email: string,
  password: string
): Promise<{ code: string; message?: string }> {
  try {
    setRecoil(loaderAtom, true)
    await Auth.signIn(email, password)
    await authenticateUser()
    setRecoil(loaderAtom, false)
    return { code: 'SUCCESS' }
  } catch (error: unknown) {
    console.log('error signing up:', error)
    // @ts-ignore FIXME
    setRecoil(authAtom, false)
    setRecoil(loaderAtom, false)

    return error as { message: string; code: string }
  }
}

type UpdateUser = {
  given_name?: string
  family_name?: string
  email?: string
  phone_number?: string
}

export async function updateUser(attributes: UpdateUser) {
  try {
    setRecoil(loaderAtom, true)
    const currentUser = await Auth.currentAuthenticatedUser()
    const res = await Auth.updateUserAttributes(currentUser, attributes)
    // @ts-ignore FIXME
    setRecoil(userAtom, res?.user)
    setRecoil(loaderAtom, false)
  } catch (error) {
    console.log('error updating user:', error)
  }
}

export async function confirmSignUp(code: string) {
  try {
    setRecoil(loaderAtom, true)
    // @ts-ignore FIXME
    const { contents } = getRecoil(userAtom)
    await Auth.confirmSignUp(contents?.user?.username, code)
    const currentUser = await Auth.currentAuthenticatedUser()
    setRecoil(userAtom, currentUser)
    setRecoil(loaderAtom, false)
  } catch (e) {
    console.error('error confirming user:', e)
    setRecoil(loaderAtom, false)
  }
}

export async function confirmSignIn(user: any, code: string) {
  return new Promise(async (res, rej) => {
    try {
      setRecoil(loaderAtom, true)
      await Auth.confirmSignIn(user, code, 'SMS_MFA')
      await authenticateUser()
      setRecoil(loaderAtom, false)
      res({ code: 'SUCCESS' })
    } catch (e) {
      console.log('error confirming user:', e)
      setRecoil(loaderAtom, false)
      res(e)
    }
  })
}

export async function authenticateUser() {
  try {
    const user = await Auth.currentUserInfo()
    if (user) {
      const authenticatedUser = await Auth.currentAuthenticatedUser()
      const token = authenticatedUser?.signInUserSession?.idToken?.jwtToken
      APIKit.defaults.headers.common['authorization'] = `Bearer ${token}`

      const user = await getUser()

      if (user?.user_id) {
        setRecoil(authAtom, authenticatedUser)
        setRecoil(userAtom, user)
        return true
      }

      setRecoil(authAtom, false)
      setRecoil(userAtom, null)
      return false
    }
  } catch (e) {
    console.log('Error Authenticating user', e)
    throw e
  }
}

export async function forgotPasswordSubmit(
  username: string,
  code: string,
  password: string
) {
  try {
    setRecoil(loaderAtom, true)
    await Auth.forgotPasswordSubmit(username, code, password)
    setRecoil(loaderAtom, false)
  } catch (e) {
    setRecoil(loaderAtom, false)
    throw e
  }
}

export async function signOut() {
  try {
    await Auth.signOut()
    setRecoil(authAtom, false)
    setRecoil(userAtom, {} as User)
  } catch (e) {
    console.log('Error logging user out', e)
  }
}

export async function resetPassword(email: string) {
  try {
    setRecoil(loaderAtom, true)
    await Auth.forgotPassword(email)
    setRecoil(loaderAtom, false)
    return { code: 'SUCCESS' }
  } catch (e) {
    setRecoil(loaderAtom, false)
    console.log('Error resetting password', e)
    return e
  }
}

export async function ChangePassword(oldPassword: string, newPassword: string) {
  try {
    setRecoil(loaderAtom, true)
    const authenticatedUser = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(authenticatedUser, oldPassword, newPassword)
    setRecoil(loaderAtom, false)
    return { code: 'SUCCESS' }
  } catch (e) {
    setRecoil(loaderAtom, false)
    console.log('Error changing password', e)
    // @ts-ignore FIXME
    return { code: 'FAILED', message: e.message }
  }
}
