import styled from '@emotion/styled'
import {
  BaseView,
  BaseViewProps,
  Button,
  CancelButton as MoxyCancelButton,
  Text
} from '../../../components'

export const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 52px 25px;
  flex: 1;
`

export const InnerFormWrapper = styled.div`
  width: 75%;
  margin-top: 10px;
  @media (max-width: 699px) {
    width: 100%;
    margin-left: 0;
  }
`

export const SaveButton = styled(Button)`
  width: 360px;
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

export const CancelButton = styled(MoxyCancelButton)`
  width: 360px;
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

export const UsernameText = styled(Text)`
  color: ${p => p.theme.colors.blue};
  font-family: SF Pro Text;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 20px;
`

export const MediaContainer = styled.div`
  display: flex;

  p {
    margin-left: 5px;
    color: #307ec5;
    margin-top: 4px;
  }
`

export const ButtonWrapper = styled.div<BaseViewProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${BaseView};
`
