import { InputHTMLAttributes, forwardRef } from 'react'
import styled from '@emotion/styled'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
`

const InputError = styled.div`
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
  color: #df301a;
`

export const StyledInput = styled.input`
  all: unset;
  background: ${p => p.theme.colors.darkGrey};
  box-shadow: 9px 9px 18px rgba(0, 0, 0, 0.1);
  border-radius: 7.2px;
  border: none;
  height: 50px;
  padding-left: 20px;
  margin-bottom: 5px;
  color: white;
`

export const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    errors?: boolean
    errorText?: string
  }
>((props, ref) => {
  return (
    <InputWrapper>
      <StyledInput {...props} ref={ref} />
      {props.errors && <InputError>{props.errorText}</InputError>}
    </InputWrapper>
  )
})
