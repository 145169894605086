//@ts-nocheck
import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'

import {
  Table,
  ToastContainer,
  TextFieldInput,
  Button
} from '../../../components'
import styled from '@emotion/styled'
import { IconButton, FormControl, MenuItem, Select } from '@mui/material'
import { permissionOptions } from '../../../utils/constants'
import { makeStyles } from '@mui/styles'
import { userAtom } from '../../../recoil/user'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import API from '../../../API'
import placeholder from '../../../assets/profile-placeholder-circular.png'
import { toast } from 'react-toastify'
import { actionAtom } from '../../../recoil/loader'
import MySVG from '../../../components/Icons'

const Wrapper = styled.div`
  height: 100vh;
`
const MediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MediaImage = styled.img`
  max-width: 45px;
  height: 45px;
  border-radius: 50px;
  margin-right: 20px;
`

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #2e2f30;
    padding: 10px;
  }

  .MuiDialogTitle-root {
    color: white;
  }
`

const AddNewButton = styled(Button)`
  width: 200px;
  padding: 20px;
`

const AddUserModal = ({ open, handleClose, addUser }) => {
  const [email, setEmail] = React.useState('')
  const isAction = useRecoilValue(actionAtom)
  return (
    <StyledDialog
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Authorized User</DialogTitle>
      <DialogContent>
        <TextFieldInput
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          onChange={v => setEmail(v.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button small onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          isAction={isAction === 'addingAuthUser'}
          disabled={!email || isAction === 'addingAuthUser'}
          small
          onClick={() => addUser({ email, access: 'view' })}
          color="primary">
          Add
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root, .MuiSelect-icon': {
      color: 'white',
      opacity: '0.5'
    },
    '& .MuiSelect-root, .MuiSelect-select, .MuiSelect-selectMenu, .MuiInputBase-input, .MuiInput-input':
      {
        minWidth: '100px',
        color: 'white',
        opacity: 0.5
      }
  },
  lowerOpacity: {
    opacity: '0.5'
  }
})

export const Remove = authorisedUser => {
  const handleRemove = async authorisedUser => {
    const response = await API.user.removeAuthorizedUser(authorisedUser)
    if (response.status === 200) {
      console.log('successfully removed')
      toast.success('Successfully removed authorized user!', {
        position: toast.POSITION.TOP_CENTER
      })
    }

    if (response.status !== 200) {
      console.log('error: response is:', response.message)
      toast.error(`${response?.message}`, {
        position: toast.POSITION.TOP_CENTER
      })
    }
    await API.user.getUser()
  }

  return (
    <IconButton
      color="secondary"
      aria-label="remove item"
      onClick={() => handleRemove(authorisedUser)}>
      <img src={MySVG.Delete} alt="delete icon" />
    </IconButton>
  )
}

function Sharing() {
  const user = useRecoilValue(userAtom)
  const [rows, setRows] = React.useState([])

  const setAction = useSetRecoilState(actionAtom)
  const [showAdd, setShowAdd] = React.useState(false)

  const classes = useStyles()

  const getValue = label =>
    permissionOptions
      .filter(o => o.label.toLowerCase() === label.toLowerCase())
      .reduce((memo, permission) => permission.value, {})

  const handleChange = async (event, authorizedUser) => {
    const access = event.target.value.toLowerCase()
    const patch = { access }

    setAction('patching-user')
    const response = await API.user.updateAccessLevel(authorizedUser, patch)
    if (response.status === 200) {
      console.log('successfully updated')
      toast.success('Access Level Saved!', {
        position: toast.POSITION.TOP_CENTER
      })
      setAction('')
    }

    if (response.status !== 200) {
      console.log('error: response is:', response.message)
      toast.error(`${response?.message}`, {
        position: toast.POSITION.TOP_CENTER
      })
    }
    await API.user.getUser()
  }

  const addUser = async data => {
    try {
      setAction('addingAuthUser')
      await API.user.addAuthorizedUser(data)
      await API.user.getUser()
      setAction('')
      setShowAdd(false)
    } catch (e) {
      console.log(e)
      setAction('')
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      //@ts-ignore FIXME
      renderCell: params => {
        return (
          <MediaContainer>
            <MediaImage
              src={params.row.photo ? params.row.photo : placeholder}
              //@ts-ignore FIXME
              index={params.row.photo}
              alt="profile"
            />
            <p>{params.row.name}</p>
          </MediaContainer>
        )
      }
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      flex: 1,
      renderCell: params => {
        return <p className={classes.lowerOpacity}>{params.row.email}</p>
      }
    },
    {
      field: 'permission',
      headerName: 'PERMISSION',
      flex: 1,
      renderCell: params => {
        return (
          <>
            <FormControl>
              <Select
                labelId="permissions-select-label"
                id="permissions-select"
                value={getValue(params.row.access)}
                onChange={e => handleChange(e, params.row)}>
                {permissionOptions?.map(option => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )
      }
    },
    {
      field: 'remove',
      headerName: 'REMOVE',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      //@ts-ignore FIXME
      renderCell: params => {
        return (
          <div className="media" style={{ cursor: 'pointer' }}>
            <Remove index={params.row.id} />
          </div>
        )
      }
    }
  ]

  React.useEffect(() => {
    const rows = user?.authorized_users?.map(
      ({ authorized_id, authorized_user, access }) => ({
        id: authorized_id,
        photo: authorized_user.photo,
        name: `${authorized_user.first_name} ${authorized_user.last_name}`,
        email: authorized_user.email,
        access
      })
    )
    //@ts-ignore FIXME
    setRows(rows)
  }, [user?.authorized_users])

  return (
    <>
      <AddUserModal
        open={showAdd}
        handleClose={() => setShowAdd(false)}
        addUser={addUser}
      />
      <Wrapper className={classes.root}>
        <Table
          title="Sharing"
          columns={columns}
          rows={rows}
          HeaderAction={() => (
            <AddNewButton onClick={() => setShowAdd(true)}>
              Add New User
            </AddNewButton>
          )}
        />
        <ToastContainer />
      </Wrapper>
    </>
  )
}

export default Sharing
