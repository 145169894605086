import APIKit from './apiFactory'
import { getUser } from './user'
import { SubscriptionType } from '../types/user'

type Subscription = {
  email: string
  type: SubscriptionType
}

export const addSubscription = async (subscription: Subscription) => {
  try {
    const res = await APIKit.post('/subscription', subscription)
    return res?.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const createPaymentIntent = async (amount: number) => {
  try {
    const res = await APIKit.post('/subscription/create-payment-intent', {
      amount
    })
    return res?.data?.clientSecret
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const createCustomer = async () => {
  try {
    const res = await APIKit.post('/subscription/create-customer')
    return res?.data?.customer
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const createCheckoutSession = async ({
  customer,
  subscription
}: {
  customer: unknown
  subscription: SubscriptionType
}) => {
  try {
    const res = await APIKit.post('/subscription/create-checkout-session', {
      customer,
      subscription
    })
    return res?.data?.session
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const deleteSubscription = async () => {
  try {
    await APIKit.delete('/subscription')
    await getUser()
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getStripeCustomer = async () => {
  try {
    const res = await APIKit.post('/subscription/stripe-customer')
    return res?.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
