import { Global, css } from '@emotion/react'
import fonts from './fonts/fonts'
import './assets/fonts/fonts.css'
import emotionReset from 'emotion-reset'
import emotionNormalize from 'emotion-normalize'

const boxSizing = css`
  * {
    box-sizing: border-box;
    &::after {
      box-sizing: border-box;
    }
    &::before {
      box-sizing: border-box;
    }
  }
`

const body = css`
  body * {
    font-family: 'SFProText-Regular';
  }
`

const base = css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'SFProText-Regular';
    font-size: 16px;
    background-color: #1c1c1c;
    color: #ffffff;
  }

  * {
    font-family: 'SFProText-Regular';
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: #1c1c1c !important;
    color: white !important;
    transition: font-size 5000s ease-in-out 0s,
      background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s; /* This transition can help override the autofill background color */
  }
`

const annotationStyles = css`
  .annotation path {
    stroke: white;
  }

  .annotation-note-label {
    fill: white;
  }
  .annotation-note-bg {
    width: 150px;
  }

  circle.handle {
    stroke: white;
  }
`

const calendarStyles = css`
  .Calendar {
    background: transparent;
    max-width: 60em;
    flex: 1;
    .Calendar__day {
      color: rgba(255, 255, 255, 0.8);
      &.-today {
        color: white !important;
      }
    }
  }

  .Calendar__header:hover {
    background: transparent !important;
  }

  .Calendar__header {
    color: white;
    button {
      background: transparent !important;
      color: white !important;
      border-radius: 5px;
    }
  }

  .Calendar__day {
    position: relative;
    border-radius: 10px !important;
  }
  .Calendar__day.strength:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #307ec5 !important;
    border-radius: 2px;
    position: absolute;
    bottom: 2px;
  }
  .Calendar__day.endurance:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #fe7f1d !important;
    border-radius: 2px;
    position: absolute;
    bottom: 2px;
  }
  .Calendar__day.free:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #00b780 !important;
    border-radius: 2px;
    position: absolute;
    bottom: 2px;
  }
  .Calendar__monthArrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='white'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='white' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  }
  .Calendar__day.interval:before {
    content: '';
    width: 5px;
    height: 5px;
    background: #df301a !important;
    border-radius: 2px;
    position: absolute;
    bottom: 2px;
  }
  .Calendar__day.multiple-workouts:before {
    content: '';
    width: 15px;
    height: 5px;
    background: linear-gradient(
      to right,
      #307ec5 25%,
      #fe7f1d 25%,
      #fe7f1d 50%,
      #00b780 50%,
      #00b780 75%,
      #df301a 75%
    );

    border-radius: 2px;
    position: absolute;
    bottom: 2px;
  }
  .custom-calendar {
    box-shadow: 0 1em 3em rgba(156, 136, 255, 0.2);
  }
`

const baseStyles = css`
  ${emotionReset}
  ${emotionNormalize}
  ${fonts}
  ${base}
  ${boxSizing}
  ${body}
  ${calendarStyles}
  ${annotationStyles}
`

export const GlobalStyles = () => <Global styles={baseStyles} />
