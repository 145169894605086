import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Autocomplete from '@mui/lab/Autocomplete'
import { Chip } from '@mui/material'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { ToolOptions } from '../components'
import theme from './../theme'

const StyledSensorAutoComplete = styled(Autocomplete)`
  ${[0, 1, 2, 3].map(
    i => css`
      .MuiChip-root[data-tag-index='${i}'] span {
        color: ${theme.colors.moxy[i]};
      }
    `
  )}
`
const StyledDataAutoComplete = styled(Autocomplete)`
  ${[0, 1, 2, 3].map(
    i => css`
      .SM02 span {
        color: ${theme.colors.green};
      }
      .SM02.RATE span {
        color: ${theme.colors.white};
      }
      .THB span {
        color: ${theme.colors.orange};
      }
      .HR span {
        color: ${theme.colors.red};
      }
    `
  )}
`

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'white',
      fontFamily: 'Oswald',
      fontSize: '19px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      paddingTop: 10,
      marginBottom: 5
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink +  .MuiInput-root': {
      marginTop: 25
    },
    '& .MuiChip-root': {
      color: 'white',
      background: 'none'
    },
    '& .MuiChip-label': {
      fontFamily: 'SFProText-Regular'
    },
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '& .MuiAutocomplete-inputRoot': {
      color: 'white'
    }
  },
  dropDownRoot: {
    width: '25%',
    '& .MuiFormLabel-root': {
      color: 'white',
      fontFamily: 'Mulish',
      fontSize: '19px',
      opacity: '60%',
      letterSpacing: '0.1px'
    },
    dropdownInputLabel: {
      fontFamily: 'Oswald',
      fontSize: '24px',
      color: 'white',
      textTransform: 'uppercase',
      letterSpacing: '1.5px'
    },
    icon: {
      color: 'white'
    },
    select: {
      color: 'white'
    },
    input: {
      color: 'white',
      fontFamily: 'Oswald'
    },
    inputRoot: {
      color: 'white',
      fontFamily: 'Oswald'
    },
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '& .MuiAutocomplete-inputRoot': {
      color: 'white'
    }
  }
})

export const MultiSelect = ({ label, options, selectSensor, selectData }) => {
  const classes = useStyles()
  const dataRef = React.useRef()
  React.useEffect(() => {
    window.x = dataRef.current
  }, [])
  if (label === 'Data') {
    const hasHR = options?.workout_metrics?.some(m => m.type === 'heartrate')
    const dataOptions = ['SM02', 'THB', 'SM02 RATE', 'HR']
      .map(i => {
        if (i !== 'HR') {
          return i
        }
        return hasHR ? i : false
      })
      .filter(i => i)

    return (
      <div className={classes.root}>
        <StyledDataAutoComplete
          ref={dataRef}
          multiple
          onChange={(e, v) => selectData(v)}
          limitTags={4}
          classes={{ inputRoot: classes.inputRoot, input: classes.input }}
          id="data-multi-select"
          options={dataOptions}
          getOptionLabel={option => option}
          defaultValue={[dataOptions[0], dataOptions[1]]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <span className={option} key={index}>
                <Chip label={option} {...getTagProps({ index })} />
              </span>
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps
              }}
              label={label}
              placeholder={label}
            />
          )}
        />
      </div>
    )
  }

  if (label === 'Sensors') {
    return (
      <div className={classes.root}>
        <StyledSensorAutoComplete
          multiple
          onChange={(e, v) => selectSensor(v)}
          disableCloseOnSelect
          limitTags={2}
          defaultValue={[options[0]]}
          classes={{ inputRoot: classes.inputRoot }}
          id="sensor-multi-select"
          options={options}
          getOptionLabel={option => option?.name}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps }}
              label={label}
              placeholder={label}
            />
          )}
        />
      </div>
    )
  }
}

export const ToolSelector = ({
  label,
  addCircleAnnotation,
  addSquareAnnotation,
  toggleEditAnnotation,
  removeAnnotations,
  addSm02Annotation,
  isEditable
}) => {
  const classes = useStyles()
  const toolOptions = ['Markup'] //'Analysis',
  const [value, setValue] = React.useState(toolOptions[0])
  const [inputValue, setInputValue] = React.useState('')

  return (
    <>
      <div className={classes.dropDownRoot}>
        <Autocomplete
          disableCloseOnSelect
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          limitTags={2}
          classes={{ inputRoot: classes.inputRoot }}
          id="tools-select"
          options={toolOptions}
          getOptionLabel={option => option}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps }}
              label={label}
              placeholder={label}
            />
          )}
        />
      </div>
      <ToolOptions
        tool={value}
        addSm02Annotation={addSm02Annotation}
        addCircleAnnotation={addCircleAnnotation}
        addSquareAnnotation={addSquareAnnotation}
        toggleEditAnnotation={toggleEditAnnotation}
        removeAnnotations={removeAnnotations}
        isEditable={isEditable}
      />
    </>
  )
}
