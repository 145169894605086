import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import { BaseView } from './Base'

const LoaderWrapper = styled.article`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${p => `rgba(0, 0, 0, ${p.backdropOpacity})`};
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoaderSectionWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  ${BaseView}
`

export const Loader = ({ visible, backdropOpacity = 0.5 }) => {
  return visible ? (
    <LoaderWrapper backdropOpacity={backdropOpacity}>
      <CircularProgress size={60} />
    </LoaderWrapper>
  ) : null
}

export const SectionLoader = props => (
  <LoaderSectionWrapper {...props}>
    <CircularProgress />
  </LoaderSectionWrapper>
)
