import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useTextFieldStyles } from './TextFieldStyles'

export const TextFieldInput = (props: TextFieldProps) => {
  const classes = useTextFieldStyles()
  return (
    <div className={classes.root}>
      <TextField variant="standard" {...props} />
    </div>
  )
}
