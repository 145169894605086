import React from 'react'
import AccountSidebar from './AccountSidebar'
import ProfileInformation from './ProfileInformation'
import Grid from '@mui/material/Grid'

function Account() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} md={4} lg={3}>
        <AccountSidebar />
      </Grid>
      <Grid item xs={10} md={8} lg={9}>
        <ProfileInformation />
      </Grid>
    </Grid>
  )
}

export default Account
