import React from 'react'
import {
  Title,
  LinearProgressWithLabel,
  Graph,
  Button
} from './../../../../components'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import SensorCarousel from './SensorCarousel'
import { Box } from '@mui/material'

const Wrapper = styled.div`
  position: relative;
  background: black;
  width: 99%;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;
  min-height: 411px;
  margin-bottom: 50px;
  h3 {
    font-family: 'Oswald';
  }
  h2 {
    font-size: 26px;
    font-family: 'SFProText-Regular';
  }

  .Slider__wrapper {
    display: none;
  }

  .GraphWrapper,
  .GraphWrapper__inner {
    padding: 0;
    svg {
      width: 100%;
    }
  }

  ${props =>
    props.$loading &&
    css`
      display: flex;
      justify-content: center;
    `}
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 20px;
  right: 0;
  bottom: 15px;
`

const LiveWorkoutData = ({
  selectedSensor,
  setNext,
  setPrev,
  workout,
  loading,
  workoutDevices,
  refresh,
  hasHr
}) => {
  const [annotations, setAnnotations] = React.useState([])

  return (
    <Box mt={4}>
      <Title>
        {workout?.ended_at ? 'Latest Workout Data' : 'Live Workout Data'}
      </Title>
      <Wrapper $loading={loading}>
        <Button
          onClick={refresh}
          small
          style={{
            position: 'absolute',
            width: 150,
            top: 5,
            right: 5
          }}>
          Reload Workouts
        </Button>
        {loading ? (
          <LoadingContainer>
            <LinearProgressWithLabel />
          </LoadingContainer>
        ) : (
          <>
            <SensorCarousel
              selectedSensor={selectedSensor}
              next={setNext}
              prev={setPrev}
              sensors={workoutDevices}
            />
            {workout && (
              <Graph
                dataDetails={{ SM02: true, THB: true, HR: hasHr }}
                annotations={annotations}
                setAnnotations={setAnnotations}
                workout={workout}
                selectedSensor={selectedSensor}
                workoutDevices={workoutDevices}
              />
            )}
          </>
        )}
      </Wrapper>
    </Box>
  )
}

export default LiveWorkoutData
