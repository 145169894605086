import { thbDataEffect } from './workout'
import theme from './../theme'
import {
  addMilliseconds,
  subMilliseconds,
  isAfter,
  isBefore,
  differenceInSeconds,
  parseISO,
  differenceInMinutes,
  differenceInHours
} from 'date-fns'

export const getSm02Rate = (workout = { workout_metrics: [] }, modulo = 5) => {
  if (workout?.workout_metrics?.length) {
    const allSm02Data = workout?.workout_metrics
      .filter(m => m.type === 'sm02')
      .map(m => ({
        user_sensor_id: m.user_sensor_id,
        x: new Date(m.recorded_at),
        y: m.value
      }))
      .sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))

    const startedAt = allSm02Data?.[0].x

    const devices = [...new Set(allSm02Data?.map(m => m.user_sensor_id))]

    const sm02Rate = devices?.reduce((memo, id) => {
      const device = {}
      device[id] = allSm02Data
        ?.filter(m => m.user_sensor_id === id)
        ?.map(m => {
          const diff = differenceInSeconds(m.x, startedAt)
          return {
            recorded_at: m.x,
            x: diff,
            y: m.y,
            x2: diff * diff,
            xy: m.y * diff
          }
        })
        ?.map((m, i, sm02Data) => {
          if (i < modulo) {
            let spread = i + 1
            let keys = [...Array(spread).keys()]
            let set = keys.map(k => sm02Data[k]).filter(m => m)

            return set
          }

          let keys = [...Array(modulo).keys()].map(k => i - k)
          const set = keys.map(k => sm02Data[k]).filter(m => m)

          return set
        })
        ?.filter(m => m)
        ?.map(set => {
          const sumX = set.map(i => i.x).reduce((memo, x) => memo + x, 0)
          const sumY = set.map(i => i.y).reduce((memo, y) => memo + y, 0)
          const sumX2 = set.map(i => i.x2).reduce((memo, x2) => memo + x2, 0)
          const sumXY = set.map(i => i.xy).reduce((memo, xy) => memo + xy, 0)
          const size = set.length

          const slope =
            (size * sumXY - sumX * sumY) / (size * sumX2 - sumX * sumX) || 0

          return {
            x: set[0]?.recorded_at,
            y: slope
          }
        })

      return { ...memo, ...device }
    }, {})

    const sm02RateMax = devices?.reduce((memo, id) => {
      const device = {}

      device[id] =
        Math.round(
          Math.max.apply(null, [...sm02Rate[id].map(r => r.y)]) * 100
        ) / 100
      return { ...memo, ...device }
    }, {})

    const sm02RateMin = devices?.reduce((memo, id) => {
      const device = {}

      device[id] =
        Math.round(
          Math.min.apply(null, [...sm02Rate[id].map(r => r.y)]) * 100
        ) / 100
      return { ...memo, ...device }
    }, {})

    return { sm02Rate, sm02RateMax, sm02RateMin }
  }
}

export const setValues = (
  workout = { workout_metrics: [] },
  setThbMax = () => {},
  setThbMin = () => {},
  workoutDevices = [],
  selectedSensor = () => {}
) => {
  const thbData = workout?.workout_metrics
    ?.filter(m => m.type === 'thb')
    ?.map(m => ({
      user_sensor_id: m.user_sensor_id,
      y: m.value,
      x: new Date(m.recorded_at)
    }))

  const { max, min } = thbDataEffect(thbData, setThbMax, setThbMin)

  const allSm02Data = workoutDevices
    ?.map(d => d.user_sensor_id)
    ?.reduce((memo, d) => {
      const device = {}
      device[d] = workout?.workout_metrics
        ?.filter(m => m.type === 'sm02' && m.user_sensor_id === d)
        ?.map(m => ({
          user_sensor_id: m?.user_sensor_id,
          y: m.value,
          x: new Date(m.recorded_at),
          barColor: m.barColor || theme.colors.green
        }))
        ?.sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))

      return { ...memo, ...device }
    }, {})

  const allThbData = workoutDevices
    ?.map(d => d?.user_sensor_id)
    ?.reduce((memo, d) => {
      const device = {}
      device[d] = workout?.workout_metrics
        ?.filter(m => m.type === 'thb' && m.user_sensor_id === d)
        ?.map(m => ({
          user_sensor_id: m.user_sensor_id,
          y: m.value,
          x: new Date(m.recorded_at)
        }))
        ?.sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))
      return { ...memo, ...device }
    }, {})

  const allHeartRateData = workout?.workout_metrics
    ?.filter(m => m.type === 'heartrate')
    ?.map(m => ({
      y: m.value,
      x: new Date(m.recorded_at)
    }))
    ?.sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))

  const currentSm02Data = selectedSensor?.length
    ? allSm02Data?.[selectedSensor[0]?.user_sensor_id]
    : allSm02Data?.[selectedSensor?.user_sensor_id] || []

  const startedAt = subMilliseconds(new Date(currentSm02Data?.[0]?.x), 1000)
  const endedAt = addMilliseconds(
    new Date(currentSm02Data?.[currentSm02Data?.length - 1]?.x),
    1000
  )
  const diff = differenceInSeconds(endedAt, startedAt)
  const seconds = (diff % 60).toString().padStart(2, '0')
  const minutes = ((diff - seconds) / 60).toString().padStart(2, '0')
  const noEndedWorkoutValue = `${minutes}:${seconds}`

  const hours = differenceInHours(
    parseISO(workout.ended_at),
    parseISO(workout.started_at)
  )
  const displayEndedAt = workout?.ended_at
    ? `${hours.toString().padStart(2, '0')}:${
        differenceInMinutes(
          parseISO(workout.ended_at),
          parseISO(workout.started_at)
        ) -
        hours * 60
      }:${(
        differenceInSeconds(
          parseISO(workout.ended_at),
          parseISO(workout.started_at)
        ) % 60
      )
        .toString()
        .padStart(2, '0')}`
    : noEndedWorkoutValue

  const initialValues = {
    display: {
      startedAt: '00:00',
      endedAt: displayEndedAt,
      thb: { min, max }
    },
    startedAt,
    endedAt,
    alarms: workout?.alarm_sm02
  }

  return {
    allSm02Data,
    allThbData,
    initialValues,
    allHeartRateData
  }
}

export const getXZoom = (
  workout = { workout_metrics: [] },
  workoutDevices = [],
  selectedSensor = () => {},
  xZoom = []
) => {
  const sm02Data = workoutDevices
    .map(d => d.user_sensor_id)
    .reduce((memo, d) => {
      const device = {}
      device[d] = workout.workout_metrics
        .filter(m => m.type === 'sm02' && m.user_sensor_id === d)
        .map(m => ({
          user_sensor_id: m.user_sensor_id,
          y: m.value,
          x: new Date(m.recorded_at),
          barColor: m.barColor || theme.colors.green
        }))
        .sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))

      return { ...memo, ...device }
    }, {})

  const totalTime = differenceInSeconds(
    parseISO(workout.ended_at),
    parseISO(workout.started_at)
  )
  const currentSm02Data = selectedSensor?.length
    ? sm02Data?.[selectedSensor[0]?.user_sensor_id]
    : sm02Data?.[selectedSensor?.user_sensor_id] || []

  const startedAt = addMilliseconds(
    new Date(currentSm02Data?.[0]?.x),
    ((xZoom[0] * totalTime) / 100) * 1000
  )
  const endedAt = subMilliseconds(
    new Date(currentSm02Data?.[currentSm02Data.length - 1]?.x),
    (((100 - xZoom[1]) * totalTime) / 100) * 1000
  )

  let initialValues = {
    display: {
      startedAt: '0:00',
      endedAt: `${differenceInMinutes(endedAt, startedAt)}:${(
        differenceInSeconds(endedAt, startedAt) % 60
      )
        .toString()
        .padStart(2, '0')}`
    },
    startedAt,
    endedAt,
    alarms: workout.alarm_sm02
  }

  if (xZoom[0] === 0) {
    initialValues.startedAt = subMilliseconds(
      new Date(currentSm02Data?.[0]?.x),
      250
    )
  }

  if (xZoom[1] === 100) {
    initialValues.endedAt = addMilliseconds(
      new Date(currentSm02Data?.[currentSm02Data.length - 1]?.x),
      250
    )
  }

  const allSm02Data = workoutDevices
    .map(d => d.user_sensor_id)
    .reduce((memo, d) => {
      const device = {}
      device[d] = workout.workout_metrics
        .filter(m => m.type === 'sm02' && m.user_sensor_id === d)
        .map(m => ({
          user_sensor_id: m.user_sensor_id,
          y: m.value,
          x: new Date(m.recorded_at),
          barColor: m.barColor || theme.colors.green
        }))
        .sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))
        .filter(
          m =>
            isAfter(m.x, initialValues.startedAt) &&
            isBefore(m.x, initialValues.endedAt)
        )

      return { ...memo, ...device }
    }, {})

  const allThbData = workoutDevices
    .map(d => d.user_sensor_id)
    .reduce((memo, d) => {
      const device = {}
      device[d] = workout.workout_metrics
        .filter(m => m.type === 'thb' && m.user_sensor_id === d)
        .map(m => ({
          user_sensor_id: m.user_sensor_id,
          y: m.value,
          x: new Date(m.recorded_at)
        }))
        .sort((a, b) => (isAfter(a.x, b.x) ? 1 : isBefore(a.x, b.x) ? -1 : 0))
        .filter(
          m =>
            isAfter(m.x, initialValues.startedAt) &&
            isBefore(m.x, initialValues.endedAt)
        )

      return { ...memo, ...device }
    }, {})

  return {
    initialValues,
    allSm02Data,
    allThbData
  }
}

export const getTHBZoom = (workout = { workout_metrics: [] }, thbZoom = []) => {
  const thbData = workout?.workout_metrics
    ?.filter(m => m.type === 'thb')
    ?.map(m => ({
      user_sensor_id: m.user_sensor_id,
      y: m.value,
      x: new Date(m.recorded_at)
    }))

  const { max: thbMax, min: thbMin } = thbDataEffect(
    thbData,
    () => {},
    () => {}
  )
  const spreadItem = (thbMax - thbMin) / 100
  const min = Math.round((thbZoom[0] * spreadItem + thbMin) * 100) / 100
  const max = Math.round((thbMax - (100 - thbZoom[1]) * spreadItem) * 100) / 100

  return { max, min }
}
