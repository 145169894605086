import { Control, RegisterOptions, useController } from 'react-hook-form'
import { Select } from './Select'
import React from 'react'

type Props = {
  control: Control
  name: string
  rules?: RegisterOptions
  label: string
  children: React.ReactNode
  value?: string
}

export const SelectForm = ({ control, name, rules, ...selectProps }: Props) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ''
  })

  return (
    <Select
      {...selectProps}
      {...inputProps}
      error={error}
      formControlInvalid={invalid}
    />
  )
}
