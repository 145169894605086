import React from 'react'
import {
  Page,
  Button,
  Title,
  Input,
  Image,
  ToastContainer
} from '../../../components'
import styled from '@emotion/styled'
import dots from '../../../assets/images/moxy-dots.png'
import MoxyLogo from '../../../assets/images/moxy.png'
import { useForm } from 'react-hook-form'
import API from '../../../API'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { emailRegexp } from '../../../utils/validation'
import { useSetRecoilState } from 'recoil'
import { userEmailAtom } from '../../../recoil/user'

const StyledPage = styled(Page)`
  background-image: url(${dots});
  background-position: 5% 15%;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-top: 5%;
  text-align: center;
  h1 {
    font-family: 'SFPro-Semibold';
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .Wrapper__button {
    margin-top: 25px;
    display: flex;
  }

  .Wrapper__heading {
    justify-content: center;
  }

  .Wrapper__input {
    margin-bottom: 5px;
  }

  .Wrapper__warning {
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
    font-size: 12px;
    color: #df301a;
  }
`

const ForgotPassword = () => {
  const navigate = useNavigate()
  const form = useForm({
    mode: 'onChange'
  })
  const { register, handleSubmit, formState } = form
  const { isValid, errors } = formState
  const setEmail = useSetRecoilState(userEmailAtom)

  const onSubmit = React.useCallback(
    async data => {
      if (isValid) {
        const res = await API.auth.resetPassword(data.email)
        if (res.code === 'SUCCESS') {
          setEmail(data.email)
          return navigate({
            pathname: '/enter-reset-code'
          })
        } else {
          console.log('error:', res?.message)
          toast.error(`${res?.message}`, {
            position: toast.POSITION.TOP_CENTER
          })
        }
      }
    },
    [isValid, navigate]
  )

  return (
    <StyledPage>
      <Wrapper>
        <Image source={MoxyLogo} />
        <div className="Wrapper__heading">
          <Title>Enter your email address</Title>
          <p>
            Please enter your email address used at registration and we’ll send
            you a link to create a new password:
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className={'Wrapper__input'}
            {...register('email', { required: true, pattern: emailRegexp })}
            type="email"
            placeholder="example@gmail.com"
          />
          {errors.email && errors.email.type === 'required' && (
            <div className={'Wrapper__warning'}>Invalid email</div>
          )}
          <div className="Wrapper__button">
            <Button disabled={!isValid} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Wrapper>
      <ToastContainer />
    </StyledPage>
  )
}

export default ForgotPassword
