import { atom } from 'recoil'

export const authAtom = atom({
  key: 'authAtom',
  default: false,
  dangerouslyAllowMutability: true
})

export const isOnlineAtom = atom({
  key: 'isOnlineAtom',
  default: true
})
