import styled from '@emotion/styled'

export const YSlider = styled.div`
  padding: 7.5% 0;
  padding-left: 30px;
  margin-right: -30px;
  .MuiSlider-valueLabel {
    background-color: white;
    & > span > span {
      color: black;
    }
  }
  .MuiSlider-markLabel,
  .MuiSlider-colorPrimary {
    color: white;
  }
`

export const Sm02Slider = styled.div`
  padding: 0 11.5%;
  margin-left: 15px;
  width: 50%;
  margin-top: 50px;
  margin-bottom: -60px;

  .MuiSlider-valueLabel > span > span {
    color: black;
  }
  .MuiSlider-markLabel,
  .MuiSlider-colorPrimary {
    color: white;
  }

  p {
    margin-left: -7px;
    font-size: 12px;
  }
`

export const XSlider = styled.div`
  padding-bottom: 20px;
  padding: 0 11.5%;
  margin-left: 15px;
  .MuiSlider-valueLabel {
    & > span > span {
      color: black;
    }
  }
  .MuiSlider-valueLabel {
    background-color: white;
    transform: translateX(0) translateY(-35px) !important;
    & > span {
      width: 60px;
      height: 25px;
    }
  }

  .MuiSlider-markLabel,
  .MuiSlider-colorPrimary {
    color: white;
  }
  p {
    margin-left: -7px;
    font-size: 12px;
  }
`

export const THBSlider = styled.div`
  padding: 7.5% 0;
  padding-right: 30px;
  margin-left: -30px;
  .MuiSlider-valueLabel {
    background-color: white;
    & > span > span {
      color: black;
      font-size: 1.1em;
    }
  }

  .MuiSlider-valueLabel > span {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 20px;
  }

  .MuiSlider-markLabel,
  .MuiSlider-colorPrimary {
    color: white;
  }
`
export const GraphWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;
  background-color: black;

  .GraphWrapper__inner {
    display: flex;
    padding: 20px 0 0;

    img {
      position: absolute;
      bottom: 20px;
      left: 25px;
    }
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`
