import styled from '@emotion/styled'
import { MenuItem } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../../../API'
import {
  Button,
  SelectForm,
  TextFieldInput,
  Title,
  ToastContainer
} from '../../../components'
import { Form } from '../../../components/Form/Form'

const Wrapper = styled.div`
  ${p => p.theme.colors.white};
  height: 100vh;
  flex: 1;
  position: relative;
  padding: 52px 25px;
`

const SaveButton = styled(Button)`
  width: 360px;
  margin-top: 35px;

  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const InnerFormWrapper = styled.div`
  margin-left: 15px;
  width: 75%;
  @media (max-width: 699px) {
    width: 100%;
    margin-left: 0;
  }
`

const AddUser = () => {
  const [isLoading, setLoading] = useState(false)
  const [isAction, setAction] = useState(false)
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    register,
    watch,
    getValues,
    formState: { errors }
  } = useForm()

  const firstName = register('first_name', { required: true })
  const lastName = register('last_name', { required: true })
  const email = register('email', { required: true })

  const saveChanges = async () => {
    setLoading(true)
    setAction(true)
    const values = getValues()

    try {
      await API.auth.signUp({
        given_name: values.first_name,
        family_name: values.last_name,
        email: values.email,
        password: 'Temp123!'
      })

      await API.subscription.addSubscription({
        email: values.email,
        type: values.sub
      })

      setAction(false)
      setLoading(false)
      navigate('/users')
    } catch (error) {
      setAction(false)
      setLoading(false)
      console.log(error)
      //@ts-ignore
      const message = error?.message || 'Error signing up user'
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  return (
    <Wrapper>
      <Title>Add New User</Title>

      <Form onSubmit={handleSubmit(saveChanges)}>
        <InnerFormWrapper>
          <TextFieldInput
            label="First Name *"
            type="text"
            error={Boolean(errors.first_name)}
            name={firstName.name}
            InputLabelProps={{ shrink: Boolean(watch('first_name')) }}
            onChange={firstName.onChange}
            inputRef={firstName.ref}
            helperText={errors.first_name && 'First name is required'}
          />
          <TextFieldInput
            label="Last Name *"
            name={lastName.name}
            error={Boolean(errors.last_name)}
            InputLabelProps={{ shrink: Boolean(watch('last_name')) }}
            onChange={lastName.onChange}
            inputRef={lastName.ref}
            helperText={errors.last_name && 'Last name is required'}
          />
          <TextFieldInput
            label="Email *"
            name={email.name}
            error={Boolean(errors.email)}
            InputLabelProps={{ shrink: Boolean(watch('email')) }}
            onChange={email.onChange}
            inputRef={email.ref}
            helperText={errors.email && 'Email is required'}
          />

          <SelectForm
            label="Subscription *"
            control={control}
            name="sub"
            rules={{ required: 'Subscription is required' }}>
            <MenuItem value="trial">Trial</MenuItem>
            <MenuItem value="free">Free</MenuItem>
            <MenuItem value="pro">Monthly</MenuItem>
            <MenuItem value="lifetime">Lifetime</MenuItem>
          </SelectForm>

          <ButtonWrapper>
            <SaveButton disabled={isAction} type="submit">
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Add User'
              )}
            </SaveButton>
          </ButtonWrapper>
        </InnerFormWrapper>
      </Form>

      <ToastContainer />
    </Wrapper>
  )
}
export default AddUser
