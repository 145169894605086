import styled from '@emotion/styled'
import { MenuItem } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../../../API'
import { UpdateUserPatch } from '../../../API/user'
import {
  Button,
  CancelButton,
  ConfirmCancelDialog,
  Form,
  SelectForm,
  TextFieldInput,
  Title,
  ToastContainer
} from '../../../components'

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  padding: 52px 25px;
  flex: 1;
`

const SaveActionButton = styled(Button)`
  width: 360px;
  margin-left: 10px;
  @media (max-width: 599px) {
    max-width: 100%;
  }
`
const DeleteActionButton = styled(CancelButton)`
  width: 360px;

  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

const LoadingLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
`

const InnerFormWrapper = styled.div`
  margin-left: 15px;
  width: 75%;
  @media (max-width: 699px) {
    width: 100%;
    margin-left: 0;
  }
`

const EditAccount = () => {
  const [isLoading, setLoading] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [user, setUser] = useState<any>(null)
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    getValues,
    clearErrors,
    formState: { isDirty, errors }
  } = useForm()
  const navigate = useNavigate()
  const params = useParams()
  const firstName = register('first_name', { required: true })
  const lastName = register('last_name', { required: true })
  const email = register('email', { required: true })

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const users = await API.user.getUsers()
      //@ts-ignore FIXME
      const user = users.find(user => user.user_id === Number(params.id))
      setUser(user)
      setValue('user_id', user.user_id)
      setValue('email', user.email)
      setValue('subscription', user?.subscription?.type || 'free')
      setValue('last_name', user.last_name)
      setValue('first_name', user.first_name)
      setLoading(false)
    })()
  }, [])

  const saveChanges = async () => {
    setLoading(true)
    setSaving(true)

    const values = getValues()

    const response = await API.user.updateUser(values as UpdateUserPatch)

    //@ts-ignore FIXME
    if (response?.status === 200) {
      toast.success('User updated!', {
        position: toast.POSITION.TOP_CENTER
      })
      setSaving(false)
      setLoading(false)
      clearErrors()
    }
    //@ts-ignore FIXME
    if (response?.status !== 200) {
      setSaving(false)
      setLoading(false)
      //@ts-ignore FIXME
      toast.error(`${response?.message}`, {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  const deleteUser = async () => {
    setDeleting(true)
    setShowConfirm(false)

    const values = getValues()
    const response = await API.user.deleteUser(values.user_id)

    // @ts-ignore FIXME
    if (response?.status === 204) {
      toast.success('User deleted!', {
        position: toast.POSITION.TOP_CENTER
      })
      setDeleting(false)
      setLoading(false)
      clearErrors()
      navigate('/users')
    }
    //@ts-ignore FIXME
    if (response?.status !== 204) {
      setDeleting(false)
      setLoading(false)
      //@ts-ignore FIXME
      toast.error(`${response?.message}`, {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }

  return (
    <>
      <ConfirmCancelDialog
        open={showConfirm}
        titleText={`Are you sure you want to delete ${user?.first_name} ${user?.last_name} and all their data?`}
        text="This action can't be undone."
        confirmText="Confirm Deletion"
        handleClose={() => setShowConfirm(false)}
        handleConfirm={deleteUser}
        isAction={isDeleting}
      />
      <Wrapper>
        <Title>Edit User</Title>
        {isLoading ? (
          <LoadingLayout>
            <CircularProgress size={50} />
          </LoadingLayout>
        ) : (
          <Form onSubmit={handleSubmit(saveChanges)}>
            <InnerFormWrapper>
              <TextFieldInput
                label="First Name"
                type="text"
                error={Boolean(errors.first_name)}
                name={firstName.name}
                InputLabelProps={{ shrink: Boolean(watch('first_name')) }}
                onChange={firstName.onChange}
                inputRef={firstName.ref}
                helperText={errors.first_name && 'First name is required'}
              />

              <TextFieldInput
                label="Last Name"
                name={lastName.name}
                error={Boolean(errors.last_name)}
                InputLabelProps={{ shrink: Boolean(watch('last_name')) }}
                onChange={lastName.onChange}
                inputRef={lastName.ref}
                helperText={errors.last_name && 'Last name is required'}
              />

              <TextFieldInput
                label="Email"
                name={email.name}
                error={Boolean(errors.email)}
                InputLabelProps={{ shrink: Boolean(watch('email')) }}
                onChange={email.onChange}
                inputRef={email.ref}
                helperText={errors.email && 'Email is required'}
              />

              <SelectForm
                label="Subscription"
                control={control}
                name="subscription"
                value={getValues('subscription')}>
                <MenuItem value="trial">Trial</MenuItem>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="lifetime">Lifetime</MenuItem>
              </SelectForm>

              <ButtonWrapper>
                <DeleteActionButton
                  disabled={isDeleting || isSaving || !isDirty}
                  isAction={isDeleting}
                  onClick={() => setShowConfirm(true)}
                  type="button">
                  Delete user and all their data
                </DeleteActionButton>
                <SaveActionButton
                  disabled={isSaving || isDeleting || !isDirty}
                  isAction={isSaving}
                  type="submit">
                  Save Changes
                </SaveActionButton>
              </ButtonWrapper>
            </InnerFormWrapper>
          </Form>
        )}
        <ToastContainer />
      </Wrapper>
    </>
  )
}
export default EditAccount
