import React from 'react'
import styled from '@emotion/styled'
import { Button, TextFieldInput } from '../../components'
import { useRecoilValue } from 'recoil'
import { devicesAtom } from '../../recoil/user'
import { setValues, getTHBZoom, getXZoom, getSm02Rate } from '../../utils/graph'
import { SectionLoader } from '../Loader'
import { VictoryBar, VictoryLine, VictoryAxis, Bar } from 'victory'
import Slider from '@mui/material/Slider'
import {
  YSlider,
  XSlider,
  THBSlider,
  GraphWrapper,
  Wrapper,
  Sm02Slider
} from './GraphStyling'
import theme from '../../theme'
import {
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO
} from 'date-fns'
import d3 from '../../components/D3'

let makeAnnotations
let makeSm02Annotations

const EditNoteWrapper = styled.div`
  padding: 20px;
  margin-left: 20px;
  background: ${p => p.theme.colors.darkGrey};
  width: 300px;
  border-radius: 12px;

  p {
    color: white;
    font-size: 14px;
    font-family: Oswald;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    height: auto;
    margin-bottom: 20px;
  }

  & > div {
    display: flex;
    flex-direction: row;
    button:first-child {
      margin-right: 10px;
    }
  }
`

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  padding: 0 6% 20px;
  margin-top: -5%;
`

const NewBar = props => {
  return (
    <Bar
      className="barRef"
      {...props}
      ariaLabel={JSON.stringify({
        recorded_at: props.datum.x,
        value: props.datum.y
      })}
    />
  )
}

export const Graph = ({
  workout,
  selectedSensor,
  workoutDevices,
  dataDetails = { SM02: true, THB: true },
  annotations = [],
  setAnnotations = () => {},
  sm02RateAnnotations = [],
  setSm02RateAnnotations = () => {},
  isEditable,
  workoutInterval
}) => {
  // console.log('Single')
  // console.log('workout', workout)
  // console.log('selectedSensor', selectedSensor)
  // console.log('workoutInterval', workoutInterval)

  const svgRef = React.useRef(null)
  const devices = useRecoilValue(devicesAtom)
  const [sm02Data, setSm02] = React.useState(null)
  const [thbData, setThb] = React.useState(null)
  const [initialValues, setInitialValues] = React.useState(null)
  const [thbMax, setThbMax] = React.useState(12)
  const [thbMin, setThbMin] = React.useState(11.5)
  const [sm02RateMax, setSm02RateMax] = React.useState(5)
  const [sm02RateMin, setSm02RateMin] = React.useState(-5)
  const [hRMax, setHRMax] = React.useState(150)
  const [hRMin, setHRMin] = React.useState(50)
  const [isLoading, setLoading] = React.useState(true)
  const [selectedSensorId, setSelectedSensorId] = React.useState(null)
  const [xZoom, setXZoom] = React.useState([0, 100])
  const [yZoom, setYZoom] = React.useState([0, 100])
  const [thbZoom, setTHBZoom] = React.useState([0, 100])
  const [thbMarks, setTHBMarks] = React.useState(null)
  const [xMarks, setXMarks] = React.useState(null)
  const [sm02Rate, setSm02Rate] = React.useState(null)
  const [heartRate, setHeartRate] = React.useState(null)
  const [sm02Zoom, setSm02Zoom] = React.useState(5)
  const [label, setUpdateLabel] = React.useState(null)
  const [note, setNote] = React.useState(null)
  const [endTime, setEndTime] = React.useState(0)

  React.useEffect(() => {
    if (selectedSensor) {
      setLoading(true)
      const id = selectedSensor
        .map(sensor => sensor?.user_sensor_id)
        .reduce((memo, id) => id, '')
      if (id) {
        setSelectedSensorId(id)
      }
      setLoading(false)
    }
  }, [selectedSensor])

  React.useEffect(() => {
    if (sm02Data) {
      const { allSm02Data, allThbData, initialValues } = getXZoom(
        workout,
        workoutDevices,
        selectedSensor,
        xZoom
      )
      setSm02(allSm02Data)
      setThb(allThbData)
      setInitialValues(initialValues)
    }
  }, [xZoom])

  React.useEffect(() => {
    if (sm02Data) {
      const { max, min } = getTHBZoom(workout, thbZoom)
      setThbMax(max)
      setThbMin(min)
    }
  }, [thbZoom])

  React.useEffect(() => {
    const hasId = document.querySelector('#annotation-group')

    if (!hasId && annotations.length) {
      makeAnnotations = d3
        .annotation()
        .editMode(isEditable)
        .annotations(annotations)
        .textWrap(250)
        .on('dragend', annotation => {
          setAnnotations(prev =>
            prev.map(p => {
              if (p.id === annotation.id) {
                return {
                  ...p,
                  x: annotation.x,
                  y: annotation.y,
                  dx: annotation.dx,
                  dy: annotation.dy,
                  subject: {
                    ...annotation.subject
                  }
                }
              }
              return p
            })
          )
        })
        .on('noteclick', annotation => {
          console.log('clicked')
          setUpdateLabel(annotation?.note?.label)
          setNote(annotation)
        })

      d3.select(svgRef.current)
        .append('g')
        .attr('id', 'annotation-group')
        .style('font-size', '10px')
        .call(makeAnnotations)
    } else {
      if (hasId && annotations.length) {
        makeAnnotations?.annotations(annotations)?.update()
      }
    }
  }, [annotations, svgRef.current])

  React.useEffect(() => {
    const hasId = document.querySelector('#annotation-group')

    if (!hasId && sm02RateAnnotations.length) {
      makeSm02Annotations = d3
        .annotation()
        .editMode(true)
        .annotations(sm02RateAnnotations)
        .textWrap(250)
        .on('dragend', annotation => {
          setSm02RateAnnotations(prev =>
            prev.map(p => {
              if (p.id === annotation.id) {
                return {
                  ...p,
                  x: annotation.x,
                  y: annotation.y,
                  dx: annotation.dx,
                  dy: annotation.dy,
                  subject: {
                    ...annotation.subject
                  }
                }
              }
              return p
            })
          )

          // var annotations = document
          //   .querySelector('.annotation-subject')
          //   .getBoundingClientRect()

          // var barRef = document.querySelectorAll('.barRef')

          // var workoutMetrics = [...barRef]
          //   .map(el => ({ rect: el.getBoundingClientRect(), el }))
          //   .filter(
          //     i =>
          //       i.rect.left >= annotations.left &&
          //       i.rect.right <= annotations.right
          //   )
          //   .map(i => JSON.parse(i.el.attributes['aria-label'].value))
          //   .map(i => ({
          //     ...i,
          //     ...{
          //       type: 'sm02',
          //       user_sensor_id: selectedSensor[0].user_sensor_id
          //     }
          //   }))

          //  var smo2Rate = getSm02Rate({ workout_metrics: workoutMetrics })
        })
      d3.select(svgRef.current)
        .append('g')
        .attr('id', 'annotation-group')
        .style('font-size', '10px')
        .call(makeSm02Annotations)
    } else {
      if (hasId && annotations.length) {
        makeSm02Annotations?.annotations(sm02RateAnnotations)?.update()
      }
    }
  }, [sm02RateAnnotations, svgRef.current])

  React.useEffect(() => {
    if (makeAnnotations) {
      makeAnnotations.editMode(isEditable).update()
    }
  }, [isEditable])

  React.useEffect(() => {
    if (sm02Data) {
      const sm02RateValues = getSm02Rate(workout, sm02Zoom)

      if (sm02RateValues) {
        const { sm02Rate, sm02RateMax, sm02RateMin } = sm02RateValues

        setSm02RateMax(sm02RateMax)
        setSm02RateMin(sm02RateMin)
        setSm02Rate(sm02Rate)
      }
    }
  }, [sm02Zoom])

  React.useEffect(() => {
    if (workout) {
      const { allSm02Data, allThbData, initialValues, allHeartRateData } =
        setValues(workout, setThbMax, setThbMin, workoutDevices, selectedSensor)
      const sm02RateValues = getSm02Rate(workout, sm02Zoom)

      if (sm02RateValues) {
        const { sm02Rate, sm02RateMax, sm02RateMin } = sm02RateValues

        setSm02RateMax(sm02RateMax)
        setSm02RateMin(sm02RateMin)
        setSm02Rate(sm02Rate)
      }
      setSm02(allSm02Data)
      setThb(allThbData)
      if (allHeartRateData?.length) {
        setHeartRate(allHeartRateData)
        const minHr = Math.min(...allHeartRateData?.map(hr => hr.y))
        const maxHr = Math.max(...allHeartRateData?.map(hr => hr.y))
        setHRMax(maxHr)
        setHRMin(minHr)
      }

      setInitialValues(initialValues)
      setLoading(false)
      setXMarks({
        initialValues,
        display: [
          { value: 0, label: initialValues.display.startedAt },
          { value: 100, label: initialValues.display.endedAt }
        ]
      })
      setTHBMarks([
        { value: 0, label: initialValues.display.thb.min },
        { value: 100, label: initialValues.display.thb.max }
      ])

      if (workout?.ended_at) {
        const diff = differenceInSeconds(
          parseISO(workout.ended_at),
          parseISO(workout.started_at)
        )
        const hours = differenceInHours(
          parseISO(workout.ended_at),
          parseISO(workout.started_at),
          { roundingMethod: 'floor' }
        )
          .toString()
          .padStart(2, '0')

        const seconds = (diff % 60).toString().padStart(2, '0')

        const minutes = ((diff - seconds - hours * 60 * 60) / 60)
          .toString()
          .padStart(2, '0')

        setEndTime(`${hours}:${minutes}:${seconds}`)
      } else {
        const diff = differenceInSeconds(
          initialValues.endedAt,
          initialValues.startedAt
        )

        const hours = differenceInHours(
          initialValues.endedAt,
          initialValues.startedAt,
          { roundingMethod: 'floor' }
        )
          .toString()
          .padStart(2, '0')
        const seconds = (diff % 60).toString().padStart(2, '0')

        const minutes = ((diff - seconds - hours * 60 * 60) / 60)
          .toString()
          .padStart(2, '0')
        setEndTime(`${hours}:${minutes}:${seconds}`)
      }
    }
  }, [workout])

  //We use this effect to handle streaming of live data
  React.useEffect(() => {
    if (workout) {
      const { allSm02Data, allThbData, allHeartRateData } = setValues(
        workout,
        setThbMax,
        setThbMin,
        workoutDevices,
        selectedSensor
      )
      setSm02(allSm02Data)
      setThb(allThbData)
      setHeartRate(allHeartRateData)
    }
  }, [workout, devices])

  const width = 650
  const height = 250

  const saveLabel = () => {
    setAnnotations(prev => [
      ...prev.filter(p => p.id !== note.id),
      ...prev
        .filter(p => p.id === note.id)
        .map(p => ({ ...p, note: { label } }))
    ])
    setNote(null)
  }

  const handleChange = e => {
    setUpdateLabel(e.target.value)
  }

  return isLoading ? (
    <SectionLoader />
  ) : (
    <GraphWrapper className="GraphWrapper">
      {note && (
        <EditNoteWrapper>
          <p>Edit label</p>
          <TextFieldInput value={label} onChange={handleChange} />

          <div>
            <Button small onClick={saveLabel}>
              Save
            </Button>
            <Button small onClick={() => setNote(null)}>
              Cancel
            </Button>
          </div>
        </EditNoteWrapper>
      )}

      {dataDetails['SM02 RATE'] && (
        <Sm02Slider className="Slider__wrapper">
          <p>Sm02 Rate:</p>
          <Slider
            value={sm02Zoom}
            onChange={(e, v) => setSm02Zoom(v)}
            aria-labelledby="range-slider"
            valueLabelFormat={v => `${v}`}
            getAriaValueText={v => `${v}`}
            valueLabelDisplay="auto"
            min={0}
            max={60}
            marks={[
              { value: 0, label: '0 Sec' },
              { value: 60, label: '60 sec' }
            ]}
          />
        </Sm02Slider>
      )}

      <div className="GraphWrapper__inner">
        <YSlider className="Slider__wrapper">
          <Slider
            value={yZoom}
            orientation="vertical"
            onChange={(e, v) => setYZoom(v)}
            aria-labelledby="vertical-slider"
            valueLabelFormat={v => `${v}%`}
            getAriaValueText={v => `${v}%`}
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0%' },
              { value: 100, label: '100%' }
            ]}
          />
        </YSlider>

        <Wrapper>
          <svg
            className="outerSVG"
            viewBox={`0 0 ${width} ${height}`}
            width="95%"
            height="95%"
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            preserveAspectRatio="none">
            <VictoryAxis
              width={width}
              height={height}
              style={{
                axis: { stroke: theme.colors.grey50 },
                tickLabels: { display: 'none' }
              }}
            />

            <VictoryAxis
              width={width}
              height={height}
              dependentAxis
              domain={[yZoom[0], yZoom[1]]}
              orientation="left"
              standalone={false}
              tickValues={[
                0,
                initialValues?.alarms?.[0],
                initialValues?.alarms?.[1],
                100
              ]}
              tickFormat={t => `${t}%`}
              style={{
                grid: {
                  stroke: ({ tick }) =>
                    tick === initialValues?.alarms?.[0]
                      ? theme.colors.blue
                      : tick === initialValues?.alarms?.[1]
                      ? theme.colors.red
                      : theme.colors.grey50
                },
                tickLabels: {
                  fill: ({ tick }) =>
                    tick === initialValues?.alarms?.[0]
                      ? theme.colors.blue
                      : tick === initialValues?.alarms?.[1]
                      ? theme.colors.red
                      : theme.colors.grey50,

                  fontSize: '8'
                }
              }}
            />

            {/* Lap bars */}
            <VictoryBar
              width={width}
              height={height}
              barRatio={0.03}
              animate={{ duration: 500 }}
              domain={{
                y: [0, 100],
                x: [initialValues?.startedAt, initialValues?.endedAt]
              }}
              data={workoutInterval}
              standalone={false}
              labels={({ datum }) => datum.lap}
              style={{
                data: {
                  fill: theme.colors.white
                },
                labels: {
                  fill: theme.colors.white
                }
              }}
            />

            {dataDetails.SM02 && (
              <VictoryBar
                width={width}
                height={height}
                barRatio={0.8}
                animate={{ duration: 500 }}
                domain={{
                  y: [yZoom[0], yZoom[1]],
                  x: [initialValues?.startedAt, initialValues?.endedAt]
                }}
                dataComponent={<NewBar />}
                data={sm02Data?.[selectedSensorId]}
                standalone={false}
                style={{
                  data: {
                    fill: ({ datum }) => datum.barColor ?? theme.colors.green
                  }
                }}
              />
            )}

            {dataDetails.THB && (
              <>
                <VictoryAxis
                  width={width}
                  height={height}
                  dependentAxis
                  domain={[thbMin, thbMax]}
                  tickValues={[thbMin, thbMax]}
                  orientation="right"
                  standalone={false}
                  style={{
                    axis: { display: 'none' },
                    tickLabels: {
                      fill: theme.colors.orange,
                      fontSize: '8'
                    }
                  }}
                />

                <VictoryLine
                  width={width}
                  height={height}
                  animate={{ duration: 500 }}
                  interpolation="basis"
                  domain={{
                    y: [thbMin, thbMax],
                    x: [initialValues?.startedAt, initialValues?.endedAt]
                  }}
                  data={thbData?.[selectedSensorId]}
                  standalone={false}
                  style={{ data: { stroke: theme.colors.orange } }}
                />
              </>
            )}

            {dataDetails['SM02 RATE'] && (
              <>
                <VictoryLine
                  width={width}
                  height={height - 18}
                  animate={{ duration: 500 }}
                  interpolation="basis"
                  domain={{
                    y: [
                      sm02RateMin[selectedSensorId],
                      sm02RateMax[selectedSensorId]
                    ],
                    x: [initialValues?.startedAt, initialValues?.endedAt]
                  }}
                  data={sm02Rate[selectedSensorId]}
                  standalone={false}
                  style={{ data: { stroke: '#fff', zIndex: 1 } }}
                />

                <VictoryAxis
                  width={width}
                  height={height}
                  dependentAxis
                  domain={[
                    sm02RateMin[selectedSensorId],
                    sm02RateMax[selectedSensorId]
                  ]}
                  domainPadding={{ y: 9 }}
                  tickValues={[
                    sm02RateMin[selectedSensorId],
                    sm02RateMax[selectedSensorId]
                  ]}
                  orientation="right"
                  standalone={false}
                  style={{
                    axis: { display: 'none' },
                    tickLabels: {
                      fill: theme.colors.white,
                      fontSize: '8'
                    }
                  }}
                />
              </>
            )}

            {dataDetails.HR && heartRate && (
              <>
                <VictoryAxis
                  width={width}
                  height={height}
                  dependentAxis
                  domain={[hRMin, hRMax]}
                  domainPadding={{ y: -9 }}
                  tickValues={[hRMin, hRMax]}
                  orientation="right"
                  standalone={false}
                  tickFormat={t => `${t} bpm`}
                  style={{
                    axis: { display: 'none' },
                    tickLabels: {
                      fill: theme.colors.red,
                      fontSize: '8'
                    }
                  }}
                />

                <VictoryLine
                  width={width}
                  height={height}
                  animate={{ duration: 500 }}
                  interpolation="basis"
                  domain={{
                    y: [hRMin, hRMax],
                    x: [initialValues?.startedAt, initialValues?.endedAt]
                  }}
                  data={heartRate}
                  standalone={false}
                  style={{ data: { stroke: theme.colors.red, zIndex: 2 } }}
                />
              </>
            )}
            <svg className="innerSVG" ref={svgRef}></svg>
          </svg>
        </Wrapper>

        <THBSlider className="Slider__wrapper">
          <Slider
            value={thbZoom}
            orientation="vertical"
            onChange={(e, v) => setTHBZoom(v)}
            marks={thbMarks}
            valueLabelFormat={v => {
              const diff = thbMarks?.[1]?.label - thbMarks?.[0]?.label
              const tick = diff / 100
              const thb = tick * v
              const addedThb = thb + thbMarks?.[0]?.label
              return Math.round(addedThb * 100) / 100
            }}
            getAriaValueText={v => `${v}%`}
            valueLabelDisplay="auto"
            aria-labelledby="vertical-slider"
          />
        </THBSlider>
      </div>
      <XSlider className="Slider__wrapper">
        <p>Time:</p>
        <Slider
          value={xZoom}
          marks={xMarks?.display}
          valueLabelFormat={v => {
            const difference = differenceInSeconds(
              parseISO(workout?.ended_at),
              parseISO(workout?.started_at)
            )
            const tick = difference / 100
            const currentSeconds = tick * v
            const addedSeconds = addSeconds(
              xMarks?.initialValues?.startedAt,
              currentSeconds
            )
            // const display = `${differenceInMinutes(
            //   addedSeconds,
            //   xMarks?.initialValues?.startedAt
            // )}:${(
            //   differenceInSeconds(
            //     addedSeconds,
            //     xMarks?.initialValues?.startedAt
            //   ) % 60
            // )
            //   .toString()
            //   .padStart(2, '0')}`

            // return display

            const hours = differenceInHours(
              addedSeconds,
              xMarks.initialValues.startedAt,
              { roundingMethod: 'floor' }
            )
              .toString()
              .padStart(2, '0')

            const minutes =
              differenceInMinutes(
                addedSeconds,
                xMarks.initialValues.startedAt
              ) -
              hours * 60

            const seconds = (
              differenceInSeconds(
                addedSeconds,
                xMarks.initialValues.startedAt
              ) % 60
            )
              .toString()
              .padStart(2, '0')

            return `${hours}:${minutes}:${seconds}`
          }}
          valueLabelDisplay="auto"
          onChange={(e, v) => setXZoom(v)}
          aria-labelledby="range-slider"
        />
      </XSlider>
      <TimeWrapper className="TimeWrapper">
        <div>00:00:00</div>
        <div>{endTime}</div>
      </TimeWrapper>
    </GraphWrapper>
  )
}
