import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Input as MUIInput
} from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { useState } from 'react'
import { useTextFieldStyles } from './TextFieldStyles'

export const TextFieldPassword = (props: TextFieldProps) => {
  const classes = useTextFieldStyles()
  const [hidden, setHidden] = useState(true)

  return (
    <div className={classes.root}>
      <FormControl variant="standard" error={props.error}>
        <InputLabel htmlFor="standard-adornment-password">
          {props.label}
        </InputLabel>
        <MUIInput
          onChange={props.onChange}
          value={props.value}
          type={hidden ? 'password' : 'text'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setHidden(!hidden)}>
                {hidden ? (
                  <Visibility sx={{ color: '#307ec5' }} />
                ) : (
                  <VisibilityOff sx={{ color: '#307ec5' }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}
