import styled from '@emotion/styled'
import { Button } from '../components'
import theme from './../theme'
import MySVG from '../components/Icons'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import OpenWithIcon from '@mui/icons-material/OpenWith'

export const IconContainerButton = styled(Button)`
  height: 23px;
  background: transparent;
  text-transform: none;
  padding: 0;
  display: flex;
  flex: 1;
  p {
    color: ${theme.colors.blue};
    font-family: 'SFProText-Regular';
    font-size: 13.5px;
  }
`

export const ToolOptions = ({
  tool,
  addCircleAnnotation,
  addSquareAnnotation,
  toggleEditAnnotation,
  removeAnnotations,
  addSm02Annotation,
  isEditable
}) => {
  if (tool === 'Markup') {
    return (
      <>
        <IconContainerButton onClick={removeAnnotations}>
          <img src={MySVG.DeleteBlue} alt="close icon" />
          <p>Remove Markup</p>
        </IconContainerButton>
        <IconContainerButton onClick={toggleEditAnnotation}>
          {isEditable ? (
            <>
              <img src={MySVG.DrawIcon} alt="pencil icon" />
              <p>Edit Annotations</p>
            </>
          ) : (
            <>
              <OpenWithIcon style={{ color: '#307EC5' }} />
              <p>Move Annotations</p>
            </>
          )}
        </IconContainerButton>
        <IconContainerButton onClick={addSquareAnnotation}>
          <img src={MySVG.Rectangle} alt="rectangle icon" />
          <p>Box</p>
        </IconContainerButton>
        <IconContainerButton onClick={addCircleAnnotation}>
          <RadioButtonUncheckedIcon
            stroke={theme.colors.blue}
            style={{ fill: theme.colors.blue }}
          />
          <p>Circle</p>
        </IconContainerButton>
      </>
    )
  }
  if (tool === 'Analysis') {
    return (
      <>
        <IconContainerButton onClick={removeAnnotations}>
          <img src={MySVG.DeleteBlue} alt="close icon" />
          <p>Remove Analysis</p>
        </IconContainerButton>
        <IconContainerButton onClick={addSm02Annotation}>
          <img src={MySVG.Slope} alt="Slope icon" />
          <p>Slope Analysis</p>
        </IconContainerButton>
        <IconContainerButton>
          <img src={MySVG.AVA} alt="AVA icon" />
          <p>Average Value Analyzer</p>
        </IconContainerButton>
      </>
    )
  } else return <></>
}
