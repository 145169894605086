import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MenuItem, Select } from '@mui/material'
import { NavLink as Link, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Image, Text } from '.'
import { spoofedIdAtom, userAtom } from '../recoil/user'
import accountIcon from './../assets/Account.svg'
import bluetoothIcon from './../assets/Bluetooth.svg'
import MoxyLogo from './../assets/Moxy-tagline-logo-white.png'
import sharingIcon from './../assets/Sharing.svg'
import graphIcon from './../assets/graph-icon.svg'
import placeholder from './../assets/profile-placeholder.png'
import userProfileIcon from './../assets/user_profile.svg'
import { User } from '../types/user'

export const DashboardButton = styled(Link)`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 55px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  background: black;
  color: ${p => p.theme.colors.grey};
  font-family: 'SFProText-Regular';
  font-size: 14px;
  text-decoration: none;
  @media only screen and (max-width: 900px) {
    justify-content: center;
  }
`

const DashboardText = styled.span`
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 35px;
  height: 35px;
  border-radius: 50px;

  img {
    margin-right: 10px;
    @media only screen and (max-width: 900px) {
      margin-right: 0;
    }
  }
`

export const Wrapper = styled.div`
  padding: 0 20px;
  background: black;
  min-height: 100vh;

  @media only screen and (max-width: 900px) {
    padding: 0 10px;
  }

  .active {
    background-color: #2e2f30;
    color: #307ec5;

    .button__icon--blue {
      filter: invert(48%) sepia(37%) saturate(961%) hue-rotate(167deg)
        brightness(86%) contrast(90%);
    }
  }

  a:focus {
    background-color: #2e2f30;
    color: #307ec5;

    .button__icon--blue {
      filter: invert(48%) sepia(37%) saturate(961%) hue-rotate(167deg)
        brightness(86%) contrast(90%);
    }
  }
`

const ProfileItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const ProfilePhotoContainer = styled.div<{ isRenderValue?: boolean }>`
  display: flex;
  height: 35px;
  ${p =>
    !p.isRenderValue &&
    css`
      @media only screen and (max-width: 900px) {
        display: none;
      }
    `}

  ${p =>
    p.isRenderValue &&
    css`
      @media only screen and (max-width: 900px) {
        flex: 1;
        justify-content: center;
      }
    `}
`
const ProfilePhoto = styled.img`
  margin-right: 20px;
`

const ProfileName = styled(Text)<{ isRenderValue?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  ${p =>
    p.isRenderValue &&
    css`
      @media only screen and (max-width: 900px) {
        display: none;
      }
    `}
`

const ProfileUser = ({
  user,
  isRenderValue
}: {
  user: User
  isRenderValue?: boolean
}) => {
  return (
    <ProfileItemContainer>
      <ProfilePhotoContainer isRenderValue={isRenderValue}>
        <ProfilePhoto
          src={user?.photo ? user?.photo : placeholder}
          alt="profile"
        />
      </ProfilePhotoContainer>
      <ProfileName isRenderValue={isRenderValue}>
        {user?.first_name} {user?.last_name}
      </ProfileName>
    </ProfileItemContainer>
  )
}

const Sidebar = () => {
  const baseUser = useRecoilValue(userAtom)
  const [spoofedId, setSpoofedId] = useRecoilState(spoofedIdAtom)
  const [user, setUser] = React.useState(baseUser as User)
  const [usersList, setUsersList] = React.useState([] as User[])
  const navigate = useNavigate()

  React.useEffect(() => {
    setUsersList(
      baseUser?.authed_users?.map(u => u.user)?.concat([baseUser]) || []
    )
  }, [baseUser])

  useEffect(() => {
    if (spoofedId) {
      const spoofedUser = usersList?.find(u => u.user_id === spoofedId)
      if (spoofedUser) {
        setUser(spoofedUser)
      }
    }
  }, [spoofedId, usersList])

  const getUser = (userId: string) => {
    const user = usersList.find(u => u.user_id === userId) as User
    return user
  }

  const changeUser = (userId: User['user_id']) => {
    const user = usersList.find(u => u.user_id === userId) as User
    setSpoofedId(userId)
    setUser(user)
    navigate('/')
  }

  return (
    <Wrapper>
      <Link to="/">
        <Image source={MoxyLogo} />
      </Link>

      <Select
        label="User"
        onChange={event => changeUser(event.target.value)}
        value={user.user_id}
        renderValue={(selectedValue: User['user_id']) => (
          <ProfileUser user={getUser(selectedValue)} isRenderValue />
        )}
        sx={{ width: '100%', background: 'white', color: 'black' }}>
        {usersList
          .filter(u => u.user_id !== user.user_id)
          .map((user, key) => (
            <MenuItem key={key} value={user.user_id}>
              <ProfileUser user={user} />
            </MenuItem>
          ))}
      </Select>

      <DashboardButton to="/">
        <MediaContainer>
          <img
            className="button__icon--blue"
            src={graphIcon}
            alt="Graph icon"
          />
        </MediaContainer>
        <DashboardText>Data</DashboardText>
      </DashboardButton>

      <DashboardButton to="/sensors">
        <MediaContainer>
          <img
            className="button__icon--blue"
            src={bluetoothIcon}
            alt="Bluetooth icon"
          />
        </MediaContainer>
        <DashboardText>Sensors</DashboardText>
      </DashboardButton>

      <DashboardButton to="/sharing">
        <MediaContainer>
          <img
            className="button__icon--blue"
            src={sharingIcon}
            alt="Sharing icon"
          />
        </MediaContainer>
        <DashboardText>Sharing</DashboardText>
      </DashboardButton>

      <DashboardButton to="/account">
        <MediaContainer>
          <img
            className="button__icon--blue"
            src={accountIcon}
            alt="Account icon"
          />
        </MediaContainer>
        <DashboardText>Account</DashboardText>
      </DashboardButton>

      {baseUser?.role === 'ADMIN' && (
        <DashboardButton to="/users">
          <MediaContainer>
            <img
              className="button__icon--blue"
              src={userProfileIcon}
              alt="Account icon"
            />
          </MediaContainer>
          <DashboardText>Users</DashboardText>
        </DashboardButton>
      )}
    </Wrapper>
  )
}

export default Sidebar
