import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import { useRecoilValue } from 'recoil'
import styled from '@emotion/styled'

import { Title, SectionLoader, Button } from '../../../components'
import MySVG from '../../../components/Icons'

import { actionAtom } from '../../../recoil/loader'

const StyledSnapShots = styled.div`
  .heading {
    margin: 50px 25px;
  }

  .heading--oswald {
    margin-top: 40px;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: 'Oswald';
    line-height: 36px;
    letter-spacing: 1.5px;
  }

  .SnapShot__wrapper {
    display: flex;
    flex-direction: row;
  }
`

const SnapShotWrapper = styled.div`
  display: flex;
  background-image: url(${p => p.snapshot});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 150px;
  &:not(:first-child) {
    margin-left: 20px;
  }
  &:first-child {
    margin-left: 10px;
  }
`

const Wrapper = styled.div`
  display: flex;
  & > div {
    position: relative;
    &:hover {
      .buttonWrapper {
        opacity: 1;
      }
    }
  }
  .buttonWrapper {
    opacity: 0;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -15px;
    transition: all 0.3s ease-in-out;
  }
`

const SnapShot = ({ snapshot, deleteSnapshot }) => {
  const [isOpen, setOpen] = React.useState(false)
  const isAction = useRecoilValue(actionAtom)
  return (
    <Wrapper>
      <div>
        {isAction === `deletingSnapshot-${snapshot.snapshot_id}` ? (
          <SectionLoader
            ml={-15}
            mt={-20}
            position="absolute"
            top="50%"
            left="50%"
          />
        ) : (
          <span className="buttonWrapper">
            <Button onClick={() => setOpen(true)} p="10px" height="50px">
              <img src={MySVG.Zoom} alt="close icon" />
            </Button>
            <Button
              onClick={() => deleteSnapshot(snapshot.snapshot_id)}
              p="10px"
              height="50px">
              <img src={MySVG.DeleteWhite} alt="close icon" />
            </Button>
          </span>
        )}
        <SnapShotWrapper snapshot={snapshot.snapshot} />
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={snapshot.snapshot}
          onCloseRequest={() => setOpen(false)}
        />
      )}
    </Wrapper>
  )
}

const SnapShots = ({ snapshots = [], deleteSnapshot }) => {
  console.log('snapshots', snapshots)
  return snapshots.length > 0 ? (
    <StyledSnapShots>
      <Title className="heading--oswald">SnapShots:</Title>
      <div className="SnapShot__wrapper">
        {snapshots.map(snapshot => (
          <SnapShot snapshot={snapshot} deleteSnapshot={deleteSnapshot} />
        ))}
      </div>
    </StyledSnapShots>
  ) : null
}

export default SnapShots
