import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect
} from '@mui/material'

import { FieldError } from 'react-hook-form'
import { useTextFieldStyles } from './TextFieldStyles'

type Props = {
  value?: string
  error?: FieldError
  label: string
  formControlInvalid: boolean
  children: React.ReactNode
}

export const Select = ({
  label,
  error,
  formControlInvalid,
  children,
  ...selectProps
}: Props) => {
  const classes = useTextFieldStyles()

  return (
    <div className={classes.root}>
      <FormControl variant="standard" error={formControlInvalid}>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <MuiSelect
          {...selectProps}
          labelId={`${label}-label`}
          id={`${label}-labelId`}
          label={label}>
          {children}
        </MuiSelect>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </div>
  )
}
