import React from 'react'
import styled from '@emotion/styled'
import { FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { Table } from '../../../components'
import MySVG from '../../../components/Icons'
import { activeDevicesState } from '../../../recoil/user'
import { useRecoilValue } from 'recoil'
import { sensorPlacementOptions } from '../../../utils/constants'
import { makeStyles } from '@mui/styles'
import API from '../../../API'

const Wrapper = styled.div`
  height: 100vh;

  .cellPadding {
    padding: 25px 12px;
  }

  p {
    color: ${p => p.theme.colors.blue};
    font-size: 16px;
  }
`

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root, .MuiSelect-icon': {
      color: 'white',
      opacity: 0.5
    },
    '& .MuiSelect-root, .MuiSelect-select, .MuiSelect-selectMenu, .MuiInputBase-input, .MuiInput-input':
      {
        minWidth: '300px',
        color: 'white',
        opacity: 0.5
      }
  },
  lowerOpacity: {
    color: 'white !important',
    opacity: 0.5
  }
})

const Add = ({ index }) => {
  const [show, setShow] = React.useState(true)

  const handleChange = async (event, id) => {
    const location = JSON.parse(event.target.value)
    const patch = { ...{ user_sensor_id: id }, ...location }
    await API.sensor.updateSensor(patch)
    await API.user.getUser()
  }

  return (
    <div>
      {show ? (
        <IconButton
          color="secondary"
          aria-label="add a location"
          onClick={() => setShow(!show)}>
          <img src={MySVG.Add} alt="add icon" />
          <p>Add</p>
        </IconButton>
      ) : (
        <FormControl>
          <Select
            labelId="sensor-select-label"
            id="sensor-select"
            onChange={e => handleChange(e, index)}>
            {sensorPlacementOptions?.map(option => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export const Remove = index => {
  const handleRemove = async index => {
    const sensor = index.sensor
    await API.sensor.removeSensor(sensor)
    await API.user.getUser()
  }

  return (
    <IconButton
      color="secondary"
      aria-label="remove item"
      onClick={() => handleRemove(index)}>
      <img src={MySVG.Delete} alt="delete icon" />
    </IconButton>
  )
}

function Sensors() {
  const devices = useRecoilValue(activeDevicesState)
  const classes = useStyles()
  const [rows, setRows] = React.useState([])
  console.log(devices)
  React.useEffect(() => {
    const rows = devices
      .map(({ user_sensor_id, name, body_part_side, body_part, type }) => ({
        id: user_sensor_id,
        name,
        location: body_part
          ? JSON.stringify({ body_part_side, body_part })
          : null,
        type
      }))
      ?.sort((a, b) => (a.user_sensor_id < b.user_sensor_id ? -1 : 1))
    setRows(rows)
    console.log(rows)
  }, [devices])

  const handleChange = async (event, device) => {
    const location = JSON.parse(event.target.value)
    const patch = { ...{ user_sensor_id: device.id }, ...location }
    await API.sensor.updateSensor(patch)
    await API.user.getUser()
  }

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      renderCell: params => {
        return <div className="cellPadding">{params.row.name}</div>
      }
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      flex: 1,
      renderCell: params => {
        return (
          <>
            {params.row.location !== null ? (
              <FormControl>
                <Select
                  labelId="sensor-select-label"
                  id="sensor-select"
                  value={params.row.location}
                  onChange={e => handleChange(e, params.row)}>
                  {sensorPlacementOptions?.map(option => (
                    <MenuItem
                      key={option.id}
                      value={JSON.stringify(option.value)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: 'pointer' }}>
                <Add index={params.row.id} />
              </div>
            )}
          </>
        )
      }
    },
    {
      field: 'type',
      headerName: 'TYPE',
      flex: 1,
      renderCell: params => {
        return <p className={classes.lowerOpacity}>{params.row.type}</p>
      }
    },
    {
      field: 'remove',
      headerName: 'REMOVE',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: params => {
        return <Remove sensor={params.row} />
      }
    }
  ]

  return (
    <Wrapper className={classes.root}>
      <Table title={'Sensors'} columns={columns} rows={rows} />
    </Wrapper>
  )
}

export default Sensors
