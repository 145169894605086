import React from 'react'
import {
  ToolSelector,
  MultiSelect
  // ToolOptions
} from '../../../components'
import styled from '@emotion/styled'
import theme from '../../../theme'
import Grid from '@mui/material/Grid'

const Wrapper = styled.div`
  display: flex;
  background: black;
  padding: 20px;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);

  p {
    font-family: 'SFProText-Regular';
    letter-spacing: 1px;
    line-height: 24px;
  }

  .Wrapper__col {
    padding-right: 5px;
    padding-left: 5px;
  }

  .Wrapper__row {
    margin-top: 30px;
    justify-content: space-around;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
`

const Box = styled.div`
  background: ${theme.colors.darkGrey};
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;
  padding: 10px;
  p {
    font-family: 'Oswald';
    letter-spacing: 1px;
    line-height: 24px;
  }
`

const MarkupBox = styled.div`
  padding: 5px 15px;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  p {
    letter-spacing: 1px;
    line-height: 24px;
  }
`

const GraphArea = ({
  currentWorkout,
  sensors,
  selectSensor,
  setDataDetails,
  addCircleAnnotation,
  addSquareAnnotation,
  toggleEditAnnotation,
  removeAnnotations,
  addSm02Annotation,
  isEditable
}) => {
  const selectData = data => {
    const selectedData = data.reduce((memo, key) => {
      const dataItem = {}
      dataItem[key] = true
      return { ...memo, ...dataItem }
    }, {})

    setDataDetails(selectedData)
  }
  console.log(sensors)
  return (
    <Wrapper>
      <Grid container>
        <Grid item className="Wrapper__col" sm={3}>
          <Box>
            <MultiSelect
              label="Sensors"
              options={sensors.map(device => {
                // const deviceRegex = /Moxy5[\w]{0,1}[\s]{0,1}(\d+):.+/g
                // const name = deviceRegex?.exec(device?.name?.trim())?.[1]
                return device
              })}
              selectSensor={selectSensor}
            />
          </Box>
        </Grid>
        <Grid item className="Wrapper__col" sm={3}>
          <Box>
            <MultiSelect
              label="Data"
              options={currentWorkout}
              selectData={selectData}
            />
          </Box>
        </Grid>
        <Grid item className="Wrapper__col" sm={6}>
          <MarkupBox>
            <ToolSelector
              label={'Tools:'}
              addCircleAnnotation={addCircleAnnotation}
              addSquareAnnotation={addSquareAnnotation}
              toggleEditAnnotation={toggleEditAnnotation}
              removeAnnotations={removeAnnotations}
              addSm02Annotation={addSm02Annotation}
              isEditable={isEditable}
            />
          </MarkupBox>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default GraphArea
