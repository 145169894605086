import Account from '../assets/Account.svg'
import Add from '../assets/Add.svg'
import AppStore from '../assets/app-store.svg'
import Arrow from '../assets/Arrow.svg'
import AVA from '../assets/AVA.svg'
import Bluetooth from '../assets/Bluetooth.svg'
import GooglePlay from '../assets/google-play.svg'
import DownArrow from '../assets/down.svg'
import Graph from '../assets/graph-icon.svg'
import LeftArrow from '../assets/chevron-left.svg'
import Lock from '../assets/Lock.svg'
import DrawIcon from '../assets/pencil.svg'
import Rectangle from '../assets/Rectangle.svg'
import RightArrow from '../assets/chevron-right.svg'
import Sharing from '../assets/Sharing.svg'
import Slope from '../assets/Slope.svg'
import Subtract from '../assets/Subtract.svg'
import TextBox from '../assets/TextBox.svg'
import Delete from '../assets/trash.svg'
import DeleteBlue from '../assets/trash-blue.svg'
import DeleteWhite from '../assets/trash-white.svg'
import Zoom from '../assets/zoom.svg'
import Camera from '../assets/camera-white.svg'
import Export from '../assets/ios_share.svg'
import CheckmarkCircle from '../assets/checkmark-circle.svg'

const MySVG = {
  Account,
  Add,
  AppStore,
  Arrow,
  AVA,
  Bluetooth,
  Camera,
  CheckmarkCircle,
  Delete,
  DeleteBlue,
  DeleteWhite,
  DownArrow,
  DrawIcon,
  Export,
  LeftArrow,
  Lock,
  GooglePlay,
  Graph,
  Rectangle,
  RightArrow,
  Sharing,
  Slope,
  Subtract,
  TextBox,
  Zoom
}

export default MySVG
