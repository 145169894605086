import APIKit from './apiFactory'

export const updateSensor = async sensor => {
  try {
    const res = await APIKit.patch(`/sensor/${sensor.user_sensor_id}`, sensor)
    if (res.status === 200) {
      return { code: 'SUCCESS' }
    }
    return { code: 'FAILED', message: 'Something went wrong.' }
  } catch (e) {
    console.log(e)
    return { code: 'FAILED', message: e.message }
  }
}

export const removeSensor = async sensor => {
  try {
    const res = await APIKit.delete(`/sensor/${sensor.id}`)
    if (res.status === 200) {
      return { code: 'SUCCESS' }
    }
    return { code: 'FAILED', message: 'Something went wrong.' }
  } catch (e) {
    console.log(e)
    return { code: 'FAILED', message: e.message }
  }
}
