import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BootstrapApp } from './components'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { GlobalStyles } from './base-styles'
import theme from './theme'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles'

const root = ReactDOM.createRoot(document.getElementById('root')!)
const muiTheme = createTheme()

root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <RecoilNexus />
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <GlobalStyles />
        <BrowserRouter>
          <BootstrapApp>
            <App />
          </BootstrapApp>
        </BrowserRouter>
      </MuiThemeProvider>
    </ThemeProvider>
  </RecoilRoot>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
