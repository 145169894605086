import React from 'react'
import { Page } from '../../components'
import styled from '@emotion/styled'
import dots from '../../assets/images/moxy-dots.png'
import moxy from '../../assets/images/moxy.png'

const StyledPage = styled(Page)`
  background-image: url(${dots});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: flex-start;
`
const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 360px;
`

const MoxyImage = styled.div`
  background-image: url(${moxy});
  background-position: 5% 15%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
`

const Splash = () => {
  return (
    <StyledPage>
      <Wrapper>
        <MoxyImage />
      </Wrapper>
    </StyledPage>
  )
}
export default Splash
