import styled from '@emotion/styled'
import BackgroundShape from '../assets/Shape.png'

export const BasePage = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background: ${p => p.theme.colors.black};
  color: white;
`
export const Page = styled(BasePage)`
  background-image: url(${BackgroundShape});
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: contain;
`
