import React from 'react'
import styled from '@emotion/styled'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '../../../components'

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #2e2f30;
    padding: 10px;
    color: white;

    p {
      margin-bottom: 10px;
    }
  }

  .MuiDialogTitle-root {
    color: white;
  }
`

const ContinueWorkoutModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <StyledDialog
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Workout Status</DialogTitle>
      <DialogContent>
        <p>
          Workout has been going on for more than an hour, is this workout
          finished?
        </p>
        <p>Or do you wanna keep hammering?</p>
      </DialogContent>
      <DialogActions>
        <Button small color="primary" onClick={handleClose}>
          Keep Hammering
        </Button>
        <Button small color="primary" onClick={handleConfirm}>
          Workout Finished
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ContinueWorkoutModal
