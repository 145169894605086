import React from 'react'
import { useRecoilValue } from 'recoil'
import { Title } from '../../../components'
import { workoutHistoryAtom } from '../../../recoil/user'
import GraphArea from '../Data/HistoricalWorkoutData/GraphArea'
import {
  getMonth,
  getYear,
  getDate,
  parseISO,
  differenceInSeconds
} from 'date-fns'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    border-left: none;
  }
`

const DayWorkouts = ({ currentWorkout }) => {
  const [highlights, setHighlights] = React.useState([])
  const workouts = useRecoilValue(workoutHistoryAtom)
  React.useEffect(() => {
    const startedAt = parseISO(currentWorkout?.started_at)
    const selectedDay = {
      year: getYear(startedAt),
      month: getMonth(startedAt) + 1,
      day: getDate(startedAt)
    }
    const newHighlights = workouts
      .map(w => ({
        ...w,
        type: w.type,
        key: `${getYear(parseISO(w.started_at))}-${
          getMonth(parseISO(w.started_at)) + 1
        }-${getDate(parseISO(w.started_at))}`,
        totalTime: differenceInSeconds(
          parseISO(w.ended_at),
          parseISO(w.started_at)
        )
      }))
      .filter(
        h =>
          h.key ===
          `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`
      )
    setHighlights(newHighlights)
  }, [workouts, currentWorkout])

  return (
    <Wrapper>
      <Title className="heading--oswald">
        Workouts - {currentWorkout?.date}
      </Title>
      <GraphArea highlights={highlights} />
    </Wrapper>
  )
}

export default DayWorkouts
