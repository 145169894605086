//@ts-nocheck
import { FormControlLabel, RadioGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Compressor from 'compressorjs'
import { format } from 'date-fns'
import React from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useRecoilState, useRecoilValue } from 'recoil'
import API from '../../../API'
import { saveProfileImage } from '../../../API/user'
import cameraIcon from '../../../assets/camera.svg'
import {
  Form,
  SectionLoader,
  StyledRadio,
  TextFieldInput,
  Title,
  ToastContainer
} from '../../../components'
import { actionAtom } from '../../../recoil/loader'
import { userAtom } from '../../../recoil/user'
import {
  ButtonWrapper,
  InnerFormWrapper,
  MediaContainer,
  SaveButton,
  Wrapper
} from './styles'
import { User } from '../../../types/user'

const useStyles = makeStyles({
  weightTextField: {
    '& .Input__StyledTextField-sc-5zucmj-1': {
      marginBottom: 0
    }
  },
  root: {
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row'
    },
    '& .MuiInputBase-input': {
      opacity: '1 !important'
    }
  }
})

const ProfileInformation = () => {
  const user = useRecoilValue(userAtom)
  const [isLoading, setLoading] = React.useState(false)
  const [firstName, setFirstName] = React.useState(user?.first_name)
  const [lastName, setLastName] = React.useState(user?.last_name)
  const [email, setEmail] = React.useState(user?.email)
  const [birthday, setBirthday] = React.useState(
    format(new Date(user?.dob), 'yyyy-MM-dd')
  )
  const [weight, setWeight] = React.useState(user?.weight)
  const [weightUnit, setWeightUnit] = React.useState(user?.weight_unit)
  const classes = useStyles()
  const [isAction, setAction] = useRecoilState(actionAtom)
  const { handleSubmit } = useForm()

  const handleCompressedUpload = e => {
    const image = e[0]
    new Compressor(image, {
      quality: 0.1,
      success: compressedResult => {
        convertToBase64(compressedResult)
      }
    })
  }

  const convertToBase64 = blob => {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      var base64data = reader.result
      saveProfileImage(base64data)
    }
  }

  const handleChange = event => {
    setWeightUnit(event.target.value)
  }

  const saveChanges = async () => {
    const data = {} as User

    if (firstName !== user?.first_name) {
      data['first_name'] = firstName
    }

    if (lastName !== user?.last_name) {
      data['last_name'] = lastName
    }

    if (email !== user?.email) {
      data['email'] = email
    }

    let formattedBirthday = format(
      new Date(birthday.replace(/-/g, '/')),
      'yyyy-MM-dd'
    )
    let formattedDob = user?.dob
      ? format(new Date(user?.dob), 'yyyy-MM-dd')
      : null
    if (formattedBirthday !== formattedDob) {
      let updatedBday = new Date(birthday.replace(/-/g, '/'))
      data.dob = updatedBday
    }

    if (weight !== user?.weight) {
      data['weight'] = weight
    }

    if (weightUnit !== user?.weight_unit) {
      data['weight_unit'] = weightUnit
    }

    const isEmpty = Object.keys(data).length === 0

    if (!isEmpty) {
      setLoading(true)
      setAction('patching-user')
      const response = await API.user.patchUser(data)

      if (response?.status === 200) {
        console.log('successfully updated')
        toast.success('User info saved!', {
          position: toast.POSITION.TOP_CENTER
        })
        setAction('')
        setLoading(false)
      }

      if (response?.status !== 200) {
        console.log('error')
        toast.error(`${response?.message}`, {
          position: toast.POSITION.TOP_CENTER
        })
      }
    }
  }

  return (
    <Wrapper>
      <Title>Profile Information</Title>

      <Dropzone accept="image/*" onDrop={handleCompressedUpload}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                cursor: 'pointer'
              }}>
              <input {...getInputProps()} />
              <MediaContainer>
                <img src={cameraIcon} alt="Account icon" />
                <p>Edit profile photo</p>
              </MediaContainer>
            </div>
          </section>
        )}
      </Dropzone>

      <Form onSubmit={handleSubmit(saveChanges)}>
        <InnerFormWrapper>
          <TextFieldInput
            label="First Name"
            required
            type="text"
            onChange={v => setFirstName(v.target.value)}
            defaultValue={user.first_name}
          />
          <TextFieldInput
            label="Last Name"
            required
            type="text"
            onChange={v => setLastName(v.target.value)}
            defaultValue={user.last_name}
          />
          <TextFieldInput
            label="Email"
            required
            type="email"
            onChange={v => setEmail(v.target.value)}
            defaultValue={user.email}
          />
          <TextFieldInput
            label="Birth date"
            type="date"
            onChange={v => setBirthday(v.target.value)}
            defaultValue={birthday}
          />
          <div className={classes.weightTextField}>
            <TextFieldInput
              label="Weight"
              type="text"
              onChange={v => setWeight(parseInt(v.target.value))}
              defaultValue={user.weight}
            />
          </div>
          <div className={classes.root}>
            <RadioGroup
              aria-label="weight_unit"
              name="weightUnit"
              value={weightUnit ? weightUnit : ''}
              onChange={handleChange}>
              <FormControlLabel
                value="kg"
                control={<StyledRadio />}
                label="Kg"
              />
              <FormControlLabel
                value="lb"
                control={<StyledRadio />}
                label="Lbs"
              />
            </RadioGroup>
          </div>
          {isLoading ? (
            <ButtonWrapper mt="30px">
              <SaveButton
                isAction={isAction === 'patching-user'}
                disabled={Boolean(isAction)}
                className="button--save"
                type="submit">
                <SectionLoader />
              </SaveButton>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper mt="30px">
              <SaveButton
                isAction={isAction === 'patching-user'}
                disabled={Boolean(isAction)}
                className="button--save"
                type="submit">
                Save Changes
              </SaveButton>
            </ButtonWrapper>
          )}
        </InnerFormWrapper>
      </Form>
      <ToastContainer />
    </Wrapper>
  )
}
export default ProfileInformation
