import React from 'react'
import { Title } from './../../../../components'
import styled from '@emotion/styled'
import Calendar from '../../../../components/Calendar'
import GraphArea from './GraphArea'
import {
  getMonth,
  getYear,
  getDate,
  parseISO,
  format,
  differenceInSeconds
} from 'date-fns'

const Wrapper = styled.div`
  display: flex;
  width: 99%;
  overflow: hidden;
  background: black;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;
  margin-bottom: 50px;

  .Wrapper__border {
    border-right: #44444f solid 0.89px;
  }
  h3 {
    font-family: 'Oswald';
  }
  h2 {
    font-size: 26px;
    font-family: 'SFProText-Regular';
  }
`

const HistoricalWorkoutData = ({ workout, loading }) => {
  const defaultValue = {
    year: getYear(new Date()),
    month: getMonth(new Date()) + 1,
    day: getDate(new Date())
  }
  const [selectedDay, setSelectedDay] = React.useState(defaultValue)
  const [highlights, setHighlights] = React.useState([])
  const [customDaysClassName, setCustomDaysClassName] = React.useState([])

  React.useEffect(() => {
    if (workout?.length) {
      const datesObj = workout
        .map(w => ({
          key: format(parseISO(w.started_at), 'MMMM d, yyyy'),
          type: w.type,
          date: w.started_at
        }))
        .reduce((memo, date, index) => {
          memo[date.key] = {
            types: memo?.[date.key]?.types
              ? [...new Set([...memo?.[date.key]?.types, date.type])].flat()
              : [date.type],
            year: getYear(parseISO(date.date)),
            month: getMonth(parseISO(date.date)) + 1,
            day: getDate(parseISO(date.date))
          }
          return memo
        }, {})

      const dates = Object.keys(datesObj)
        .map(key => datesObj[key])
        .map(d => ({
          ...d,
          className: d.types.length > 1 ? ['multiple-workouts'] : [d.types]
        }))

      setCustomDaysClassName(dates)
    }
  }, [workout])

  React.useEffect(() => {
    const selectedKey = `${selectedDay.year}-${selectedDay.month
      .toString()
      .padStart(2, '0')}-${selectedDay.day.toString().padStart(2, '0')}`

    const newHighlights = workout
      .map(w => {
        const parsedStartDate = parseISO(w.started_at)
        const parsedEndDate = parseISO(w.ended_at)
        return {
          ...w,
          type: w.type,
          key: `${getYear(parsedStartDate)}-${(getMonth(parsedStartDate) + 1)
            .toString()
            .padStart(2, '0')}-${getDate(parsedStartDate)
            .toString()
            .padStart(2, '0')}`,
          totalTime: differenceInSeconds(parsedEndDate, parsedStartDate)
        }
      })
      .filter(h => h.key === selectedKey)
    setHighlights(newHighlights)
  }, [selectedDay, workout])

  return (
    <>
      <Title>Historical Workout Data</Title>
      <Wrapper>
        <Calendar
          value={selectedDay}
          customDaysClassName={customDaysClassName}
          onChange={setSelectedDay}
        />

        <GraphArea highlights={highlights} loading={loading} />
      </Wrapper>
    </>
  )
}

export default HistoricalWorkoutData
