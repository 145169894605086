import React from 'react'

import { Title } from './Text'
import { DataGrid as MaterialDataGrid, GridColDef } from '@mui/x-data-grid'
import styled from '@emotion/styled'
import MySVG from './Icons'
import { IconButton } from '@mui/material'
import { removeSensor } from '../API/sensor'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin-left: 35px;
  min-height: 100vh;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
`

const NoData = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: black;
  border-radius: 12px;
`

const DataGrid = styled(MaterialDataGrid)`
  &.MuiDataGrid-root {
    border: none;
    color: white;
    margin-top: 35px;
    .MuiSvgIcon-root {
      fill: #fff;
    }

    .MuiDataGrid-overlayWrapperInner {
      max-height: 100%;
    }
    .MuiDataGrid-columnsContainer {
      border-bottom: none;
    }
    .MuiDataGrid-columnHeaderWrapper {
      text-transform: uppercase;
    }

    .MuiDataGrid-cell {
      border-bottom: 1px solid #2d3748;
    }

    .MuiTablePagination-root {
      color: white;
    }

    .MuiDataGrid-overlay {
      background: ${p => p.theme.colors.darkGrey};
    }
  }
`

export const Remove = ({ type, sensor }: { type: string; sensor: any }) => {
  const handleEditClick = () => {
    if (type === 'sensor') {
      removeSensor(sensor)
    }
  }

  return (
    <IconButton
      color="secondary"
      aria-label="remove item"
      onClick={handleEditClick}>
      <img src={MySVG.Delete} alt="delete icon" />
    </IconButton>
  )
}

export const Table = ({
  title,
  columns,
  rows = [],
  HeaderAction
}: {
  title: string
  columns: GridColDef[]
  rows: any[]
  HeaderAction: () => JSX.Element
}) => {
  if (title === 'Laps/Intervals:') {
    return (
      <Wrapper style={{ minHeight: 'auto' }}>
        <Header>
          <Title>{title}</Title>
        </Header>
        <>
          <DataGrid
            loading={rows.length === 0}
            autoHeight
            rows={rows}
            columns={columns}
          />
        </>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <Header>
          <Title>{title}</Title>
          {HeaderAction && <HeaderAction />}
        </Header>
        <>
          {rows?.length ? (
            <DataGrid
              loading={rows.length === 0}
              rows={rows}
              columns={columns}
            />
          ) : (
            <NoData>No Data</NoData>
          )}
        </>
      </Wrapper>
    )
  }
}
