import React, { useMemo } from 'react'
import {
  BreadcrumbsNav,
  Image,
  LinearProgressWithLabel,
  MultiSensorGraph,
  OutlineButton,
  Text,
  TextFieldInput,
  Title
} from '../../../components'
import CircularProgress from '@mui/material/CircularProgress'
import { IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled'
import EnduranceIcon from './../../../assets/images/endurance.png'
import FreeWorkoutIcon from './../../../assets/images/free-workout.png'
import IntervalIcon from './../../../assets/images/interval.png'
import StrengthIcon from './../../../assets/images/strength.png'
import WorkoutSummary from './WorkoutSummary'
import LapsIntervals from './LapsIntervals'
import DayWorkouts from './DayWorkouts'
import SnapShots from './SnapShots'

import theme from '../../../theme'
import { capitalize, toTimeString } from '../../../utils/text-utils'
import { useParams } from 'react-router-dom'
import API from '../../../API'
import d3 from '../../../components/D3'
import {
  addSeconds,
  differenceInSeconds,
  format,
  isAfter,
  isBefore,
  parseISO
} from 'date-fns'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'

import { devicesAtom, workoutHistoryAtom } from '../../../recoil/user'
import GraphArea from './GraphArea'
import MySVG from '../../../components/Icons'
import { actionAtom, progressAtom } from '../../../recoil/loader'

const WorkoutContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;

  .WorkoutContainer__icon {
    width: 58px;
  }

  .WorkoutContainer__text {
    align-items: center;
  }
`

const WorkoutNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;

  & > div {
    flex: 1;
    max-width: 100%;
  }

  .WorkoutNameWrapper__buttons {
    margin-left: auto;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const GraphWrapper = styled.div`
  margin: 5px;
  background: black;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
  border-radius: 12px;

  .GraphWrapper__buttons {
    display: flex;
    width: 200px;
    padding: 20px;
  }

  .TimeWrapper {
    display: none;
  }
`

let annotationIndex = 1

const DataDetail = () => {
  const { id } = useParams()
  const screenShotRef = React.createRef()
  const [currentWorkout, setCurrentWorkout] = React.useState(null)
  const devices = useRecoilValue(devicesAtom)
  const setWorkoutHistory = useSetRecoilState(workoutHistoryAtom)
  const [workoutInterval, setWorkoutInterval] = React.useState([])
  const [currentMoxyDevice, setCurrentMoxyDevice] = React.useState(0)
  const [workoutDevices, setWorkoutDevices] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedSensor, selectSensor] = React.useState(null)
  const [dataDetails, setDataDetails] = React.useState({
    SM02: true,
    'SM02 RATE': false,
    THB: true,
    HR: false
  })
  const [workoutName, setWorkoutName] = React.useState()
  const [annotations, setAnnotations] = React.useState([])
  const [sm02RateAnnotations, setSm02RateAnnotations] = React.useState([])
  const [isEditable, setIsEditable] = React.useState(true)
  const [isAction, setAction] = useRecoilState(actionAtom)
  const [image, setImage] = React.useState(null)
  const [patchingWorkout, setPatchingWorkout] = React.useState(false)
  const setProgress = useSetRecoilState(progressAtom)
  const controller = useMemo(() => new AbortController())

  console.log('DEVICES', devices)

  const deleteSnapshot = async id => {
    await API.workout.deleteSnapshot(id)
    await getWorkout()
    setAction('')
  }

  async function getWorkout() {
    try {
      const myWorkout = await API.workout.getWorkout(id)
      const workout_metrics = await API.workout.getWorkoutMetricsBatch(
        id,
        myWorkout.metricsCount,
        controller
      )
      const workout = { ...myWorkout, workout_metrics }
      setWorkoutName(workout?.name)
      setCurrentWorkout({
        ...workout,
        date: format(parseISO(workout.started_at), 'MMMM d, yyyy'),
        totalTime: differenceInSeconds(
          parseISO(workout.ended_at),
          parseISO(workout.started_at)
        )
      })
      const workoutInterval = workout.workout_interval
        .sort((a, b) =>
          isAfter(parseISO(a.started_at), parseISO(b.started_at))
            ? 1
            : isAfter(parseISO(b.started_at), parseISO(a.started_at))
            ? -1
            : 0
        )
        .map((w, index) => {
          const duration = differenceInSeconds(
            parseISO(w.ended_at),
            parseISO(w.started_at)
          )
          const parsedDuration = toTimeString(duration)

          const avgHr = workout.workout_metrics
            .filter(m => m.type === 'heartrate')
            .filter(
              m =>
                isAfter(parseISO(m.recorded_at), parseISO(w.started_at)) &&
                isBefore(parseISO(m.recorded_at), parseISO(w.ended_at))
            )
            .map(m => m.value)
            .reduce((memo, v, index, array) => {
              if (index < array.length - 2) {
                return memo + v
              }

              if (index === array.length - 1) {
                return Math.floor((memo + v) / array.length)
              }
              return memo
            }, 0)

          let start = 0
          if (index > 0) {
            if (index === 1) {
              const previousWorkout = workout.workout_interval?.[index - 1]
              const previousDuration = differenceInSeconds(
                parseISO(previousWorkout.ended_at),
                parseISO(previousWorkout.started_at)
              )
              start = previousDuration
            } else {
              start = workout.workout_interval
                ?.slice(0, index)
                ?.map(p =>
                  differenceInSeconds(
                    parseISO(p.ended_at),
                    parseISO(p.started_at)
                  )
                )
                ?.reduce((memo, duration) => memo + duration, 0)
            }
          }
          const startDuration = toTimeString(start)

          let end = duration
          if (index > 0) {
            end = duration + start
          }
          const endDuration = toTimeString(end)

          return {
            id: index,
            lap: `Lap ${index + 1}`,
            duration: parsedDuration,
            avgHr,
            startTime: startDuration,
            endTime: endDuration,
            x: parseISO(workout.workout_interval[index].ended_at),
            y: 100
          }
        })

      setWorkoutInterval(workoutInterval)

      const workoutDevices = workout.workout_metrics
        .filter(m => m.type !== 'heartrate')
        .map(m =>
          devices
            .filter(d => d.user_sensor_id === m.user_sensor_id)
            .reduce((memo, device) => device, false)
        )
        .filter(d => d)

      const uniqueSetOfDevice = [...new Set(workoutDevices)]
      setWorkoutDevices(uniqueSetOfDevice)
      selectSensor([uniqueSetOfDevice[0]])
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    async function sync() {
      if (image) {
        await API.workout.createSnapshot(image, currentWorkout.workout_id)
        await getWorkout()
        setAction('')
        setImage(null)
      }
    }
    sync()
  }, [image])

  React.useEffect(() => {
    const currentId = workoutDevices
      .map((d, i) => {
        if (d.user_sensor_id === selectedSensor.user_sensor_id) {
          return i
        }
        return false
      })
      .filter(d => d !== false)
      .reduce((_, i) => i, 0)
    console.log('selectedSensor', selectedSensor)
    console.log('workoutDevices', workoutDevices)
    setCurrentMoxyDevice(currentId)
  }, [selectedSensor])

  React.useEffect(() => {
    async function init() {
      setLoading(true)
      await getWorkout()
      setLoading(false)
    }
    init()

    return () => {
      controller.abort()
      setProgress(0)
    }
  }, [id])

  const saveWorkoutName = async () => {
    setPatchingWorkout(true)
    await API.workout.patchWorkout(
      { name: workoutName },
      currentWorkout.workout_id
    )
    await getWorkout()
    setPatchingWorkout(false)
  }

  const getWorkoutColor = type => {
    switch (type) {
      case 'strength':
        return theme.colors.blue
      case 'interval':
        return theme.colors.red
      case 'steadyState':
      case 'endurance':
        return theme.colors.orange
      case 'freeWorkout':
      case 'free':
        return theme.colors.green
      default:
        return theme.colors.green
    }
  }

  const getWorkoutIcon = type => {
    switch (type) {
      case 'strength':
        return `${StrengthIcon}`
      case 'interval':
        return `${IntervalIcon}`
      case 'steadyState':
      case 'endurance':
        return `${EnduranceIcon}`
      case 'freeWorkout':
      case 'free':
        return `${FreeWorkoutIcon}`
      default:
        return `${FreeWorkoutIcon}`
    }
  }

  const addCircleAnnotation = async () => {
    const annotation = {
      id: annotationIndex,
      x: 100 + annotationIndex * 5,
      y: 100 + annotationIndex * 5,
      dy: -35,
      dx: 80,
      type: d3.annotationCalloutCircle,
      connector: { end: 'arrow' },
      note: {
        label: 'Annotation'
      }
    }

    setAnnotations(prev => [...prev, annotation])
    annotationIndex += 1
  }

  const addSquareAnnotation = () => {
    setAnnotations(prev => [
      ...prev,
      {
        id: annotationIndex,
        x: 100 + annotationIndex * 5,
        y: 100 + annotationIndex * 5,
        dy: -35,
        dx: 80,
        type: d3.annotationCalloutRect,
        connector: { end: 'arrow' },
        note: {
          label: 'Annotation'
        },
        subject: {
          width: -50,
          height: 100
        }
      }
    ])

    annotationIndex += 1
  }

  const removeAnnotations = () => {
    d3.select('g.annotations').remove()
    setAnnotations([])
  }

  const addSm02Annotation = () => {
    setSm02RateAnnotations(prev => [
      ...prev,
      {
        id: annotationIndex,
        x: 100 + annotationIndex * 5,
        y: 100 + annotationIndex * 5,
        dy: -35,
        dx: 80,
        type: d3.annotationCalloutRect,
        note: {
          label: 'Sm02 Rate:'
        },
        subject: {
          width: -50,
          height: 100
        }
      }
    ])

    annotationIndex += 1
  }

  const exportFit = async () => {
    setAction('creatingFitFile')

    setTimeout(async () => {
      const url = await API.workout.getFitFile(currentWorkout.workout_id)
      saveAs(url, `${currentWorkout.name}.fit`)
      setAction('')
    }, 1000)
  }

  const exportCSVfile = () => {
    setAction('creatingCSVFile')
    setTimeout(async () => {
      const url = await API.workout.getCSVFile(currentWorkout.workout_id)
      saveAs(url, `${currentWorkout.name}.csv`)
      setAction('')
    }, 1000)
  }

  const takeScreenShot = node => {
    setAction('creatingSnapshot')
    setIsEditable(false)
    setTimeout(() => {
      html2canvas(node, { quality: 0.5 }).then(canvas => {
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d')
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = canvas.width
        const cropHeight = canvas.height

        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop
        )

        const base64Image = croppedCanvas.toDataURL()

        setImage(base64Image)
        return base64Image
      })
    }, 500)
  }

  const finishWorkout = async () => {
    setLoading(true)
    const myWorkout = await API.workout.getWorkout(id)
    const workout_metrics = await API.workout.getWorkoutMetrics(id)
    const workout = { ...myWorkout, workout_metrics }

    let patch = { ended_at: addSeconds(parseISO(workout.started_at), 1) }
    if (workout.workout_metrics.length) {
      const sortedMetrics = workout.workout_metrics?.sort((a, b) =>
        isAfter(parseISO(a.recorded_at), parseISO(b.recorded_at))
          ? 1
          : isBefore(parseISO(a.recorded_at), parseISO(b.recorded_at))
          ? -1
          : 0
      )

      patch = {
        ended_at: addSeconds(
          parseISO(sortedMetrics[sortedMetrics.length - 1]?.recorded_at),
          1
        )
      }
    }

    await API.workout.finishWorkout(workout.workout_id, patch)
    await getWorkout()
    const workouts = await API.workout.getWorkouts()
    setWorkoutHistory(workouts)
    setLoading(false)
  }

  return loading ? (
    <LoadingWrapper>
      <CircularProgress />
      <LinearProgressWithLabel />
    </LoadingWrapper>
  ) : (
    <div style={{ minHeight: '100vh' }}>
      <div ref={screenShotRef} style={{ backgroundColor: '#2e2f30' }}>
        <WorkoutContainer>
          <Title>{currentWorkout?.date}</Title>

          <div className="WorkoutContainer__icon">
            <Image source={getWorkoutIcon(currentWorkout?.type)} />
          </div>

          <div className="WorkoutContainer__text">
            <Text color={getWorkoutColor(currentWorkout?.type)}>
              {capitalize(currentWorkout?.type)}
            </Text>
          </div>
        </WorkoutContainer>
        <Title color={getWorkoutColor(currentWorkout?.type)}></Title>
        <WorkoutNameWrapper>
          <TextFieldInput
            label="Workout Name"
            value={workoutName}
            onChange={e => setWorkoutName(e.target.value)}
          />
          {currentWorkout?.name !== workoutName && !patchingWorkout && (
            <div className="WorkoutNameWrapper__buttons">
              <IconButton onClick={saveWorkoutName}>
                <CheckIcon style={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={() => setWorkoutName(currentWorkout?.name)}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
          )}
          {patchingWorkout && (
            <div className="WorkoutNameWrapper__buttons">
              <CircularProgress />
            </div>
          )}
        </WorkoutNameWrapper>
        <BreadcrumbsNav date={currentWorkout?.date} />
        <WorkoutSummary
          currentWorkout={currentWorkout}
          finishWorkout={finishWorkout}
        />

        <GraphWrapper>
          <GraphArea
            isEditable={isEditable}
            addCircleAnnotation={addCircleAnnotation}
            addSquareAnnotation={addSquareAnnotation}
            removeAnnotations={removeAnnotations}
            addSm02Annotation={addSm02Annotation}
            toggleEditAnnotation={() => setIsEditable(!isEditable)}
            currentWorkout={currentWorkout}
            sensors={workoutDevices}
            selectSensor={selectSensor}
            currentMoxyDevice={currentMoxyDevice}
            dataDetails={dataDetails}
            setDataDetails={setDataDetails}
          />
          <MultiSensorGraph
            annotations={annotations}
            setAnnotations={setAnnotations}
            setSm02RateAnnotations={setSm02RateAnnotations}
            sm02RateAnnotations={sm02RateAnnotations}
            isEditable={isEditable}
            workoutDevices={workoutDevices}
            workout={currentWorkout}
            selectedSensor={selectedSensor}
            dataDetails={dataDetails}
            workoutInterval={workoutInterval}
          />
          <div className="GraphWrapper__buttons">
            <OutlineButton
              mr={2}
              onClick={exportCSVfile}
              disabled={isAction === 'creatingCSVFile'}>
              {isAction === 'creatingCSVFile' ? (
                <CircularProgress size={20} />
              ) : (
                <img
                  src={MySVG.Export}
                  alt="camera icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Export CSV
            </OutlineButton>
            <OutlineButton
              mr={2}
              onClick={exportFit}
              disabled={isAction === 'creatingFitFile'}>
              {isAction === 'creatingFitFile' ? (
                <CircularProgress size={20} />
              ) : (
                <img
                  src={MySVG.Export}
                  alt="camera icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Export Fit
            </OutlineButton>
            <OutlineButton
              disabled={isAction === 'creatingSnapshot'}
              onClick={() => takeScreenShot(screenShotRef.current)}>
              {isAction === 'creatingSnapshot' ? (
                <CircularProgress size={20} />
              ) : (
                <img
                  src={MySVG.Camera}
                  alt="camera icon"
                  style={{ marginRight: 5 }}
                />
              )}
              Snapshot
            </OutlineButton>
          </div>
        </GraphWrapper>
      </div>
      <SnapShots
        snapshots={currentWorkout?.workout_snapshot}
        deleteSnapshot={deleteSnapshot}
      />
      <LapsIntervals workoutInterval={workoutInterval} />
      <DayWorkouts currentWorkout={currentWorkout} />
    </div>
  )
}

export default DataDetail
