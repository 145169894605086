import styled from '@emotion/styled'
import { Star } from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import API from '../../../API'
import {
  Button,
  ConfirmCancelDialog,
  ConfirmSuccessDialog,
  SubTitle,
  Text,
  Title
} from '../../../components'
import { userAtom } from '../../../recoil/user'
import AccountSidebar from './AccountSidebar'
import { CancelButton, UsernameText, Wrapper } from './styles'
import { useSubscription } from '../../../hooks/useSubscription'
import { capitalize } from '../../../utils/text-utils'
import { signOut } from '../../../API/authentication'
import { useMediaQuery } from '@mui/material'

const SubWrapper = styled(Wrapper)`
  @media (max-width: 699px) {
    padding: 20px;
  }
`
const InfoTextContainer = styled(Box)`
  width: 75%;
  @media (max-width: 699px) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  justify-content: space-between;
  margin-top: 20px;
`

export const Subscription = () => {
  const user = useRecoilValue(userAtom)
  const subscription = useSubscription()
  const [openFailed, setOpenFailed] = useState(false)
  const [openRedirect, setOpenRedirect] = useState(false)
  const [sessionUrl, setSessionUrl] = useState('')
  const [openCancel, setOpenCancel] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [isSaving, setSaving] = useState('')
  const isMobile = useMediaQuery('(max-width:700px)')

  useEffect(() => {
    if (isMobile) {
      setOpenRedirect(true)
    }
  }, [isMobile])

  const deleteSubscription = async () => {
    setDeleting(true)
    await API.subscription.deleteSubscription()
    setOpenCancel(false)
    setDeleting(false)
  }

  const startMonthly = async () => {
    setSaving('monthly')
    const customer = await API.subscription.createCustomer()
    const session = await API.subscription.createCheckoutSession({
      customer,
      subscription: 'monthly'
    })
    if (session?.url) {
      setSessionUrl(session.url)
      setOpenConfirm(true)
    }
    setSaving('')
  }

  const startLifetime = async () => {
    setSaving('lifetime')

    const customer = await API.subscription.createCustomer()
    const session = await API.subscription.createCheckoutSession({
      customer,
      subscription: 'lifetime'
    })
    if (session?.url) {
      setSessionUrl(session.url)
      setOpenConfirm(true)
    }
    setSaving('')
  }

  return (
    <SubWrapper>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setOpenSuccess(false)}>
        <Alert
          onClose={() => setOpenSuccess(false)}
          variant="filled"
          severity="info"
          sx={{ width: '100%' }}>
          Your subscription has been successfully submitted! Please allow 24
          hours for processing.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailed}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setOpenFailed(false)}>
        <Alert
          onClose={() => setOpenFailed(false)}
          variant="filled"
          severity="error"
          sx={{ width: '100%' }}>
          An error occurred while processing your subscription. Please try again
          later.
        </Alert>
      </Snackbar>
      <ConfirmCancelDialog
        open={openCancel}
        titleText={`Are you sure you want to cancel your ${user?.subscription?.type} Subscription?`}
        text={`You can still access your ${user?.subscription?.type} features until it expires on ${subscription.endDate}. `}
        confirmText="Cancel Subscription"
        handleClose={() => setOpenCancel(false)}
        handleConfirm={deleteSubscription}
        isAction={isDeleting}
      />
      <ConfirmSuccessDialog
        open={openConfirm}
        titleText="Redirecting to Secure Payment"
        text="You are about to be redirected to Stripe, our secure payment partner, to complete your transaction. Please do not close your browser or navigate away from this page."
        confirmText="Proceed to payment"
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={() => window.open(sessionUrl, '_self')}
      />
      <ConfirmSuccessDialog
        open={openRedirect}
        titleText="You have been redirected to the WebTool"
        text={`You are logged in as ${user?.email}. If this is the incorrect user you can log out and log in as the correct user.`}
        confirmText="Proceed to WebTool"
        cancelText="Log Out"
        handleClose={signOut}
        handleConfirm={() => setOpenRedirect(false)}
      />
      <Title>Moxy Portal Premium Subscription</Title>
      <UsernameText>Username: {user?.email}</UsernameText>
      <InfoTextContainer>
        <Text>
          The premium subscription enables the ability to save data from the
          Portal App to the cloud and then review and share the data using the
          Portal WebTool.
        </Text>
      </InfoTextContainer>

      {user?.subscription?.type === 'monthly' && (
        <InfoTextContainer mt="20px">
          <Text>
            If you decide to upgrade from a monthly subscription to a permanent
            one, we will automatically cancel your monthly subscription for you.
          </Text>
        </InfoTextContainer>
      )}

      {user?.subscription?.type !== 'lifetime' && (
        <ButtonWrapper>
          {user?.subscription?.type === 'monthly' ? (
            <CancelButton onClick={() => setOpenCancel(true)} type="button">
              Cancel Monthly Subscription
            </CancelButton>
          ) : (
            <Button onClick={startMonthly} isAction={isSaving === 'monthly'}>
              Monthly Subscription ($7/Month)
            </Button>
          )}

          <Button
            onClick={startLifetime}
            isAction={isSaving === 'lifetime'}
            mt="20px">
            {user?.subscription?.type === 'monthly'
              ? 'Upgrade to Permanent '
              : 'Permanent Subscription '}
            ($199 one-time payment)
          </Button>
        </ButtonWrapper>
      )}
      {user?.subscription?.type !== 'lifetime' && (
        <>
          <Box mt="20px">
            <Text>
              Current Subscription: {capitalize(subscription.type || 'Free')}
            </Text>
            {subscription?.endDate && (
              <Text>Current Expiration Date: {subscription.endDate}</Text>
            )}
          </Box>
          <InfoTextContainer mt="20px">
            <Text>
              Monthly subscription will auto-renew 2 days prior to the
              expiration date. Permanent subscription will only bill once.
            </Text>
          </InfoTextContainer>
        </>
      )}

      {user?.subscription?.type === 'lifetime' && (
        <Box mt="20px" display="flex" flexDirection="row">
          <Star sx={{ color: 'gold', marginRight: 1 }} />
          <SubTitle>You have a lifetime premium subscription!</SubTitle>
          <Star sx={{ color: 'gold', marginLeft: 1 }} />
        </Box>
      )}
    </SubWrapper>
  )
}

function SubscriptionPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} md={4} lg={3}>
        <AccountSidebar />
      </Grid>
      <Grid item xs={9} md={8} lg={9}>
        <Subscription />
      </Grid>
    </Grid>
  )
}

export default SubscriptionPage
