import React from 'react'
import {
  Page,
  Button,
  Title,
  Input,
  Image,
  ToastContainer
} from '../../../components'
import styled from '@emotion/styled'
import dots from '../../../assets/images/moxy-dots.png'
import MoxyLogo from '../../../assets/images/moxy.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import API from '../../../API'
import { passwordRegexp } from '../../../utils/validation'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import { userEmailAtom } from '../../../recoil/user'

const StyledPage = styled(Page)`
  background-image: url(${dots});
  background-position: 5% 15%;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: flex-start;

  .form__number {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-top: 5%;
  text-align: center;
  h1 {
    font-family: 'SFPro-Semibold';
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .Wrapper__heading {
    justify-content: center;
  }

  .Wrapper__input {
    margin-bottom: 5px;
  }

  .Wrapper__warning {
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
    font-size: 12px;
    color: #df301a;
  }

  .Wrapper__button {
    margin-top: 25px;
    display: flex;
  }
`

const EnterResetCode = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const form = useForm({
    mode: 'onChange'
  })
  const { control, register, handleSubmit, formState } = form
  const { isValid, errors } = formState
  const [code, setCode] = React.useState()
  const passwordRef = React.useRef()
  const email = useRecoilValue(userEmailAtom)

  const onSubmit = React.useCallback(
    async data => {
      try {
        await API.auth.forgotPasswordSubmit(email, code, data.password)
        toast.success('Password successfully reset', {
          position: toast.POSITION.TOP_CENTER
        })
        setTimeout(() => {
          navigate('/signin')
        }, 2200)
      } catch (e) {
        toast.error(e.message, {
          position: toast.POSITION.TOP_CENTER
        })
      }
    },
    [isValid]
  )

  return (
    <StyledPage>
      <Wrapper>
        <Image source={MoxyLogo} />
        <div className="Wrapper__heading">
          <Title>Check your email</Title>
          <p>
            Please check {location.email} for the code that’s needed to reset
            your Moxy password.
          </p>
        </div>
        <form className="form__number" onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Code"
            className={'Wrapper__input'}
            {...register('code', { required: true, minLength: 6 })}
            type="number"
            value={code}
            onChange={inputtedCode => setCode(inputtedCode.target.value)}
          />
          <Input
            forwardRef={passwordRef}
            control={control}
            {...register('password', {
              required: true,
              pattern: passwordRegexp
            })}
            className="Wrapper__input"
            placeholder="New Password"
            type="password"
            id="password"
            name="password"
          />
          {errors.password && errors.password.type === 'required' && (
            <div className={'Wrapper__warning'}>
              * Password must be at least 8 characters long and include 1
              capital letter, 1 lowercase letter, 1 special character (@ $ & * %
              ? !) and a number.
            </div>
          )}
          <div className="Wrapper__button">
            <Button disabled={!isValid || code?.length !== 6} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Wrapper>
      <ToastContainer />
    </StyledPage>
  )
}

export default EnterResetCode
