import { setRecoil } from 'recoil-nexus'

import { AxiosResponse } from 'axios'
import { actionAtom } from '../recoil/loader'
import { devicesAtom, userAtom } from '../recoil/user'
import APIKit from './apiFactory'

export const getUsers = async () => {
  try {
    const res = await APIKit.get('/user/all')
    if (res.status === 200) {
      return res?.data
    }
    return []
  } catch (e) {
    console.log(e)
  }
}

export const getUser = async () => {
  try {
    const res = await APIKit.get('/me')

    if (res.status === 200) {
      setRecoil(userAtom, res?.data)
      setRecoil(devicesAtom, res?.data?.user_sensors)
      return res?.data
    }
    return {}
  } catch (e) {
    console.log(e)
    throw e
  }
}
// @ts-ignore FIXME
export const getUserById = async id => {
  console.log('getting user by id', id)
  try {
    const res = await APIKit.get(`/user/${id}`)
    console.log(res)
    if (res.status === 200) {
      setRecoil(devicesAtom, res?.data?.user_sensors)
      return res?.data
    }
    return {}
  } catch (e) {
    console.log(e)
  }
}

export async function deleteUser(
  id: string
): Promise<AxiosResponse | { message: string }> {
  try {
    setRecoil(actionAtom, 'deleting')
    const res = await APIKit.delete(`/user/${id}`)
    setRecoil(actionAtom, '')
    return res
  } catch (e) {
    setRecoil(actionAtom, '')
    console.log('Error deleting user', e)
    return { message: 'Error deleting the user' }
  }
}

export type UpdateUserPatch = {
  user_id: string
  first_name?: string
  last_name?: string
  email?: string
  subscription?: string
}

export async function updateUser(
  patch: UpdateUserPatch
): Promise<AxiosResponse | { message: string }> {
  try {
    setRecoil(actionAtom, 'saving')
    const res = await APIKit.patch(`/user/${patch.user_id}`, patch)
    setRecoil(actionAtom, '')
    return res
  } catch (e) {
    setRecoil(actionAtom, '')
    console.log('Error patching user', e)
    return { message: 'Error saving the user' }
  }
}
// @ts-ignore FIXME
export async function patchUser(patch) {
  try {
    setRecoil(actionAtom, 'saving')

    if (patch.first_name || patch.last_name) {
      const firstName = patch.first_name ? { first_name: patch.first_name } : {}
      const lastName = patch.last_name ? { last_name: patch.last_name } : {}
      const namePatch = { ...firstName, ...lastName }
      await APIKit.patch('/me', namePatch)
    }

    if (patch.email) {
      await APIKit.patch('/me', { email: patch.email })
    }

    if (patch.dob || patch.weight || patch.weight_unit) {
      const dob = patch.dob ? { dob: patch.dob } : {}
      const weight = patch.weight ? { weight: patch.weight } : {}
      const weightUnit = patch.weight_unit
        ? { weight_unit: patch.weight_unit }
        : {}
      const payload = { ...dob, ...weight, ...weightUnit }
      await APIKit.patch('/me', payload)
    }

    const user = await APIKit.get('/me')
    setRecoil(actionAtom, '')
    setRecoil(userAtom, user?.data)
    return user
  } catch (e) {
    setRecoil(actionAtom, '')
    console.log('Error patching user', e)
    return { message: 'Error saving the user' }
  }
}
// @ts-ignore FIXME
export const updateAccessLevel = async (authUser, patch) => {
  try {
    const res = await APIKit.patch(`/me/authorized_user/${authUser.id}`, patch)
    if (res.status === 200) {
      return res
    }
    return { code: 'FAILED', message: 'Something went wrong.' }
  } catch (e) {
    console.log(e)
    // @ts-ignore FIXME
    return { code: 'FAILED', message: e.message }
  }
}
// @ts-ignore FIXME
export const addAuthorizedUser = async data => {
  try {
    const res = await APIKit.post('/me/authorized_user', data)
    if (res.status === 200) {
      return res
    }
  } catch (e) {
    console.log(e)

    return { message: 'Something went wrong...' }
  }
}
// @ts-ignore FIXME
export const removeAuthorizedUser = async authUser => {
  try {
    const res = await APIKit.delete(`/me/authorized_user/${authUser.index}`)
    if (res.status === 200) {
      return res
    }
    return { code: 'FAILED', message: 'Something went wrong.' }
  } catch (e) {
    console.log(e)
    // @ts-ignore FIXME
    return { code: 'FAILED', message: e.message }
  }
}

// @ts-ignore FIXME
export const saveProfileImage = async image => {
  try {
    setRecoil(actionAtom, 'savingImage')
    await APIKit.patch('/me', { photo: image })
    await getUser()
    setRecoil(actionAtom, '')
  } catch (e) {
    setRecoil(actionAtom, '')
    console.log('Error patching user', e)
    return { message: 'Error saving the user' }
  }
}
