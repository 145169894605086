import axios from 'axios'
import { Auth } from 'aws-amplify'

const BASE_URL = 'https://9uge5o7oa0.execute-api.us-east-2.amazonaws.com'

export const APIKit = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 30000
})

APIKit.interceptors.request.use(async config => {
  try {
    const authenticatedUser = await Auth.currentAuthenticatedUser()
    const token = authenticatedUser?.signInUserSession?.idToken?.jwtToken
    config.headers.authorization = `Bearer ${token}`
    return config
  } catch (e) {
    console.log(e)
    return config
  }
})

export default APIKit
