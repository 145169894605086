import { atom } from 'recoil'

export const loaderAtom = atom({
  key: 'loader',
  default: false
})

export const actionAtom = atom({
  key: 'actionAtom',
  default: ''
})

export const progressAtom = atom({
  key: 'progressAtom',
  default: 0
})
