import { IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { GridColDef, DataGrid as MaterialDataGrid } from '@mui/x-data-grid'
import { convertArrayToCSV } from 'convert-array-to-csv'
import { saveAs } from 'file-saver'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import API from '../../../API'
import { Button, Title } from '../../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin-left: 35px;
  min-height: 100vh;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
`

const AddNewButton = styled(Button)`
  width: 200px;
  padding: 20px;
`

const DownloadCSV = styled(Button)`
  width: 200px;
`

const DataGrid = styled(MaterialDataGrid)`
  &.MuiDataGrid-root {
    border: none;
    color: white;
    margin-top: 35px;
    .MuiSvgIcon-root {
      fill: #fff;
    }

    .MuiDataGrid-overlayWrapperInner {
      max-height: 100%;
    }
    .MuiDataGrid-columnsContainer {
      border-bottom: none;
    }
    .MuiDataGrid-columnHeaderWrapper {
      text-transform: uppercase;
    }

    .MuiDataGrid-cell {
      border-bottom: 1px solid #2d3748;
    }

    .MuiTablePagination-root {
      color: white;
    }

    .MuiDataGrid-overlay {
      background: ${p => p.theme.colors.darkGrey};
    }
  }
`
type users = {
  last_name: string
  first_name: string
  user_id: string
}
const Users = () => {
  const [users, setUsers] = React.useState<users[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const users: users[] = await API.user.getUsers()

      const sortedUsers = users
        .sort((a, b) => (a.last_name < b.last_name ? -1 : 1))
        .map(user => ({
          ...user,
          id: user.user_id
        }))

      setUsers(sortedUsers)
    })()
  }, [setUsers])

  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'User',
      sortable: true,
      flex: 2,
      valueGetter: params => {
        const firstName = params.row.first_name || ''
        const lastName = params.row.last_name || ''
        return `${firstName} ${lastName}`
      }
    },
    {
      field: 'email',
      sortable: true,
      headerName: 'Email',
      flex: 1.5
    },
    {
      field: 'subscription',
      headerName: 'Subscription',
      flex: 0.75,
      valueGetter: params => params?.row?.subscription?.type || 'free'
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 100,
      sortable: false,
      renderCell: params => (
        <IconButton
          style={{ flex: 1 }}
          onClick={() => navigate(`/edit-account/${params.id}`)}>
          <Edit color="secondary" />
        </IconButton>
      )
    }
  ]
  const download = async () => {
    const users = await API.user.getUsers()

    const usersCSV = convertArrayToCSV(users)
    const blob = new Blob([usersCSV], { type: 'application/octet-stream' })
    saveAs(blob, 'users.csv')
  }
  return (
    <Wrapper>
      <Header>
        <Title>Users</Title>
        <AddNewButton onClick={() => navigate('/add-user')}>
          Add New
        </AddNewButton>
      </Header>

      <DataGrid
        loading={users.length === 0}
        rows={users}
        columns={columns}
        disableRowSelectionOnClick
      />
      <DownloadCSV onClick={download}>Download users CSV</DownloadCSV>
    </Wrapper>
  )
}
export default Users
