import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { BaseText } from './Base'

export const Title = styled.h1`
  font-size: 32px;
  font-family: 'SFPro-Bold';
  color: ${p => p.theme.colors.white};
  margin: 10px 0;

  &.heading--oswald {
    margin-top: 40px;
    margin-left: 10px;
    text-transform: uppercase;
    font-family: 'Oswald';
    line-height: 36px;
    letter-spacing: 1.5px;
  }
  ${BaseText}
`

export const SubTitle = styled.h2`
  color: #fff;
  font-family: 'SFPro-Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%;
  ${BaseText}
`

export const Text = styled.p`
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
  ${p =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  ${BaseText}
`
