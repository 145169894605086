import styled from '@emotion/styled'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import EnterResetCode from './modules/Authentication/ForgotPassword/EnterResetCode'
import ForgotPassword from './modules/Authentication/ForgotPassword/ForgotPassword'
import LearnMore from './modules/Authentication/LearnMore'
import SignIn from './modules/Authentication/SignIn'
import Account from './modules/Pages/Account/Account'
import AddUser from './modules/Pages/Account/AddUser'
import ChangePassword from './modules/Pages/Account/ChangePassword'
import CreateAccount from './modules/Pages/Account/CreaateAccount/CreateAccount'
import SubscriptionPage, {
  Subscription
} from './modules/Pages/Account/Subscription'
import Users from './modules/Pages/Account/Users'
import Data from './modules/Pages/Data'
import DataDetail from './modules/Pages/DataDetail'
import Sensors from './modules/Pages/Sensors/Sensors'
import Sharing from './modules/Pages/Sharing/Sharing'

import Grid from '@mui/material/Grid'
import { useRecoilValue } from 'recoil'
import { Loader } from './components'

import { useMediaQuery } from '@mui/material'
import Container from '@mui/material/Container'
import EditAccount from './modules/Pages/Account/EditAccount'
import { authAtom } from './recoil/auth'
import { loaderAtom } from './recoil/loader'
import { userAtom } from './recoil/user'

const Wrapper = styled(Grid)`
  background: ${p => p.theme.colors.darkGrey};
`

const AuthenticatedRoutes = () => {
  const user = useRecoilValue(userAtom)
  const isMobile = useMediaQuery('(max-width:700px)')
  return isMobile ? (
    <Routes>
      <Route path="*" element={<Subscription />} />
    </Routes>
  ) : (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} lg={2} sx={{ background: 'black' }}>
          <Sidebar />
        </Grid>
        <Wrapper item xs={9} md={9} lg={10}>
          <Routes>
            <Route path="/" element={<Data />} />
            <Route path="/account" element={<Account />} />
            <Route
              path="/account/change-password"
              element={<ChangePassword />}
            />

            <Route
              path="/account/subscription"
              element={<SubscriptionPage />}
            />
            <Route path="/data-detail/:id" element={<DataDetail />} />
            <Route path="/sensors" element={<Sensors />} />
            <Route path="/sharing" element={<Sharing />} />
            {/* @ts-ignore FixME */}
            {user?.role === 'ADMIN' && (
              <>
                <Route path="/users" element={<Users />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/edit-account/:id" element={<EditAccount />} />
              </>
            )}
            {/* <Route render={() => <Redirect to={{ pathname: '/' }} />} /> */}
          </Routes>
        </Wrapper>
      </Grid>
    </Container>
  )
}

const isNotAppRoute = () => {
  return (
    window.location.pathname !== '/forgot-password' &&
    window.location.pathname !== '/enter-reset-code' &&
    window.location.pathname !== '/learn-more' &&
    window.location.pathname !== '/signin' &&
    window.location.pathname !== '/create-account'
  )
}

const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/enter-reset-code" element={<EnterResetCode />} />
      <Route path="/learn-more" element={<LearnMore />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/create-account" element={<CreateAccount />} />
    </Routes>
  )
}

const MoxyRoutes = () => {
  const isAuthenticated = useRecoilValue(authAtom)
  const showLoader = useRecoilValue(loaderAtom)
  const navigate = useNavigate()

  if (!isAuthenticated && isNotAppRoute()) {
    navigate('/signin')
  }

  return (
    <>
      <Loader visible={showLoader} />
      <ApplicationRoutes />
      {isAuthenticated && <AuthenticatedRoutes />}
    </>
  )
}

export default MoxyRoutes
