import { TextFieldProps } from '@mui/material/TextField'
import { Control, Controller } from 'react-hook-form'
import { TextFieldPassword } from './TextFieldPassword'
import React from 'react'

type TextFieldPasswordFormProps = {
  control: Control
  name: string
} & TextFieldProps

export const TextFieldPasswordForm: React.FC<TextFieldPasswordFormProps> = ({
  control,
  name,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextFieldPassword
          {...props}
          onChange={onChange}
          value={value}
          inputProps={props.inputProps}
        />
      )}
    />
  )
}
