import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import { toTimeString } from '../../../utils/text-utils'
import { Button } from '../../../components'

const StyledGrid = styled(Grid)`
  margin-top: 30px;
  .grid__item {
    background: black;
    box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.0250765);
    border-radius: 12px;
    display: flex;
    margin: 5px;
    padding: 0 20px;
    justify-content: space-around;
    align-items: center;
    height: 90%;

    .heading__title {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1px;
      font-family: 'Oswald';
    }

    .heading__item {
      font-size: 46px;
      font-family: 'Oswald';
      letter-spacing: 2.875px;
    }
  }
`

const WorkoutSummary = ({ currentWorkout, finishWorkout }) => {
  const [avgHR, setAvgHR] = React.useState(0)
  const [sensors, setSensors] = React.useState([])

  React.useEffect(() => {
    const avgHR = currentWorkout?.workout_metrics
      ?.filter(m => m.type === 'heartrate')
      ?.map(m => m.value)
      ?.reduce((memo, rate, index, array) => {
        if (array.length - 1 !== index) {
          return memo + rate
        }
        return (memo + rate) / array.length
      }, 0)

    setAvgHR(Math.round(avgHR))

    const sensorsUsed = [
      ...new Set(currentWorkout?.workout_metrics?.map(m => m.user_sensor_id))
    ]

    setSensors(sensorsUsed)
  }, [currentWorkout])

  return (
    <StyledGrid container>
      <Grid item xs={3}>
        <div className="grid__item">
          {isNaN(currentWorkout?.totalTime) ? (
            <Button onClick={finishWorkout}>Finish Workout</Button>
          ) : (
            <>
              <p className="heading__title">Total Time:</p>
              <h1 className="heading__item">
                {toTimeString(currentWorkout?.totalTime)}
              </h1>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="grid__item">
          <p className="heading__title">SENSORS USED:</p>
          <h1 className="heading__item">{sensors?.length}</h1>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="grid__item">
          <p className="heading__title">AVG. HEART RATE:</p>
          <h1 className="heading__item">{avgHR}</h1>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className="grid__item">
          <p className="heading__title">NUMBER OF LAPS:</p>
          <h1 className="heading__item">
            {currentWorkout?.workout_interval?.length}
          </h1>
        </div>
      </Grid>
    </StyledGrid>
  )
}

export default WorkoutSummary
