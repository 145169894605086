import { makeStyles } from '@mui/styles'

export const useTextFieldStyles = makeStyles({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: '5px',
      marginBottom: '15px',
      color: '#939394'
    },
    '& .MuiInputLabel-root': {
      color: '#939394',
      zIndex: 1
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#1C1C1C'
    },
    '& .MuiInputBase-input': {
      color: '#939394'
    },
    '& .MuiInput-underline:before': {
      borderBottomStyle: 'dashed',
      borderBottom: '1px solid #939394'
    }
  }
})
