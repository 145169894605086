import React, { useState, useCallback, useEffect } from 'react'
import {
  Page,
  Button,
  Checkbox,
  LinkButton,
  Input,
  Image,
  ToastContainer,
  OutlineButton
} from '../../components'
import styled from '@emotion/styled'
import dots from '../../assets/images/moxy-dots.png'
import MoxyLogo from '../../assets/images/moxy.png'
import { useForm } from 'react-hook-form'
import API from '../../API'
import { useNavigate } from 'react-router-dom'
import { emailRegexp } from '../../utils/validation'
import { toast } from 'react-toastify'
import { Form } from '../../components/Form/Form'

const StyledPage = styled(Page)`
  background-image: url(${dots});
  background-position: 5% 15%;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-top: 5%;

  h1 {
    font-family: 'SFPro-Semibold';
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-bottom: 35px;
  }

  .Wrapper__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Wrapper__warning {
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
    font-size: 12px;
    color: #df301a;
  }

  .Wrapper__footer {
    margin-top: 20px;
    text-align: center;
  }
`
interface IFormInput {
  email: string
  password: string
}

const SignIn = () => {
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe') || false
  )
  const handleCheckboxChange = () => setRememberMe(!rememberMe)

  useEffect(() => {
    localStorage.setItem('rememberMe', String(rememberMe))
  }, [rememberMe])

  const {
    register,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm<IFormInput>({
    mode: 'onChange'
  })

  const onSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      if (isValid) {
        const res = await API.auth.signIn(data.email, data.password)
        if (res?.code === 'SUCCESS') {
          return navigate('/')
        } else {
          toast.error(`${res?.message}`, {
            position: toast.POSITION.TOP_CENTER
          })
        }
      }
    },
    [isValid, navigate]
  )

  return (
    <StyledPage>
      <Wrapper>
        <Image source={MoxyLogo} />
        <h1>Sign in to your account</h1>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Input
            type="email"
            errors={Boolean(errors.email)}
            errorText="Invalid email address"
            placeholder="Enter your email"
            {...register('email', { required: true, pattern: emailRegexp })}
          />

          <Input
            type="password"
            placeholder="Enter your password"
            className="Wrapper__input"
            {...register('password')}
          />
          <div className="Wrapper__actions">
            <Checkbox
              label="Remember me"
              checked={
                rememberMe === 'true'
                  ? true
                  : rememberMe === 'false' || rememberMe === ''
                  ? false
                  : rememberMe
              }
              onChange={() => handleCheckboxChange()}
            />
            <LinkButton
              type="button"
              onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </LinkButton>
          </div>
          <Button mt="40px" type="submit">
            Sign In
          </Button>

          <OutlineButton
            mt="10px"
            type="button"
            onClick={() => navigate('/create-account')}>
            Create Account
          </OutlineButton>
        </Form>
        {/* <div className="Wrapper__footer">
          Don't have an account?{' '}
          <LinkButton onClick={() => history.push('/learn-more')}>
            Learn more
          </LinkButton>
        </div> */}
      </Wrapper>
      <ToastContainer />
    </StyledPage>
  )
}

export default SignIn
